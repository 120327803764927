.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(119, 114, 114, 0.33);

    .card {
        width: 98%;
        height: 95%;
        margin: 2% auto;

        .form1 {
            width: 45%;
            height: 80vh;
            margin: 0% auto;
            overflow-y: scroll;
            border: 2px solid black;

            h1 {
                margin-left: 40%;
            }

            .item1 {
                margin-bottom: 4px;
                margin-left: 7%;
            }

            .item {
                margin-left: 7%;

                .selset {
                    width: 80%;
                }
            }



            Input {
                width: 80%;
                border: 1px solid black;
            }

            Button {
                float: right;
                margin-right: 16%;
            }
        }
    }

    :global {
        .ant-select {
            margin-top: 10px;
            border: 1px solid black;
        }
    }
}