.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    :global {
        .ant-form-item-label {
            line-height: normal;
            justify-content: flex-end;

            label {
                height: 100%;
                white-space: normal;
                text-align: right;
                padding-right: 3px;
                font-size: 14px;
            }

        }
    }

    .form {
        width: 45%;
        margin: 2% auto;
    }

    .center {
        width: 100%;


        .tab {
            display: flex;
            overflow: hidden;

            .table {
                overflow-y: scroll;
                margin-right: 10px;
                flex-grow: 1;
                height: 400px;
                border: 1px solid rgba(0, 0, 0, 0.115);
            }
        }

        .btn {
            float: right;
            margin-right: 20px;
            margin-top: 10px;
        }

    }

}