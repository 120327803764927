.all {
  width: 100%;
  height: 100vh;
  overflow: hidden;


  .form {
    width: 70%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;

    .list {
      width: 100%;

      .list1 {
        width: 100%;

        .context {
          width: 80%;
          display: flex;

          .item {
            width: 80%;

            Input {
              width: 100%;
            }
          }


        }


      }
    }

    .list {
      width: 100%;

      .list1 {
        width: 100%;

        .page {
          width: 13%;
        }

        .context1 {
          width: 80%;
          display: flex;

          .item {
            width: 80%;

            Input {
              width: 100%;
            }
          }


        }


      }
    }

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        border-bottom: 1px solid black;

      }

      .ant-input:focus {
        box-shadow: none;

      }
    }

    .textsig {
      width: 100%;
      display: flex;

      .select1 {
        width: 20%;
        margin-left: 2%;
      }
    }

    .center {
      width: 100%;
      height: 100%;
      overflow: auto;



      .item2 {
        display: inline-block;
        width: 15%;


        Input {
          width: 100%;
          float: left;
        }
      }

      .text1 {
        width: 100%;
        margin-top: 10px;

        .textsig {
          width: 100%;
          display: flex;

          .select1 {
            width: 20%;
            margin-left: 2%;
          }
        }



        .select {
          width: 50%;

          .btn {
            float: right;
          }
        }

        .item {
          display: inline-block;
          width: 20%;

          Input {
            width: 100%;
            float: left;
          }
        }

        .itemtime {
          display: inline-block;
          width: 24%;

          .time {
            float: left;
            border: 0px;
            border-bottom: 1px solid black;
          }
        }


        .item1 {
          display: inline-block;
          width: 20%;


          Input {
            width: 100%;
            float: left;
          }
        }


      }




    }

  }

}