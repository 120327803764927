.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .form {
        width: 40%;
        margin: 2% auto;

        .item {
            width: 80%;

            Button {
                float: right;
                margin-left: 20px;
            }
        }
    }
}