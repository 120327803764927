.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .form {
        width: 40%;
        height: 20%;
        margin: 0 auto;

        .item {
            width: 100%;
            margin: 10px auto;
        }

        .ite {
            float: right;

            Button {
                margin-left: 5px;
            }

        }

        .ite1 {
            float: right;
            margin-right: 50px;


        }
    }

    .left {
        width: 100%;
        height: 80%;
        overflow: auto;
        margin-right: 10px;
        border: 1px solid rgba(0, 0, 0, 0.189);

    }

}