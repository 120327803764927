.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    h1 {
        font-size: 20px;
        text-align: center;
        font-weight: bolder;
    }

    :global {
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 100px;
        }

        .ant-input[disabled] {
            color: black;
            background-color: #fff;
            border: 1px solid black;
        }
    }

    .form {
        margin: 20px auto;
        margin-left: 10px;

    }


    .center {
        width: 100%;
        height: 80%;
        margin: 2% auto;
        overflow: auto;

    }
}