.all {
  width: 100%;
  height: 95vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 90%;
    height: 100%;
    margin: 0 auto;

    .margin {
      width: 100%;
      height: 100%;

      .ite1 {
        display: flex;
        margin-left: 35%;

        .item {
          Input {
            border-bottom: 1px solid black;
            text-align: center;
            font-size: 20px;
          }
        }

        .h1 {
          font-weight: 10px;
          text-align: center;
          font-size: 25px;
        }
      }



      .center {
        width: 100%;
        height: 100%;

        .table {
          width: 90%;
          margin: 0 auto;
          height: 100%;

          .row0 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 4%;
              }
            }

            .c2 {
              width: 80%;
            }

          }

          .row1 {
            width: 100%;
            height: 30%;
            border: 1px solid black;

            .clumn1 {
              width: 20%;
              height: 100%;
              float: left;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 60%;
              }
            }

            .clumn2 {
              width: 80%;
              height: 100%;
              float: right;
              display: flex;
              flex-direction: column;

              .a1 {
                height: 50%;
                width: 100%;
                display: flex;
                border-bottom: 1px solid black;

                .b1 {
                  width: 20%;
                  border-right: 1px solid black;
                  display: flex;

                  .item {
                    width: 60px;
                    height: 50px;
                    margin-top: 25%;
                  }

                  p {
                    margin-top: 27%;
                  }
                }

                .b2 {
                  width: 80%;
                  display: flex;
                  flex-direction: column;

                  .e1 {
                    height: 32%;
                    border-bottom: 1px solid black;
                    display: flex;

                    .f1 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f2 {
                      width: 20%;
                      height: 100%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f4 {
                      width: 20%;
                      border-right: 1px solid black;
                    }

                    .f5 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f6 {
                      width: 20%;

                    }
                  }

                  .e2 {
                    height: 68%;
                    display: flex;

                    .f1 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 25%;
                      }
                    }

                    .f2 {
                      width: 20%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 25%;
                      }
                    }

                    .f4 {
                      width: 20%;
                      border-right: 1px solid black;


                    }

                    .f5 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 35%;
                      }
                    }

                    .f6 {
                      width: 20%;
                    }
                  }
                }
              }

              .a2 {
                height: 50%;
                width: 100%;
                display: flex;

                .b1 {
                  width: 20%;
                  border-right: 1px solid black;
                  display: flex;

                  .item {
                    width: 60px;
                    height: 50px;
                    margin-top: 26%;
                  }

                  p {
                    margin-top: 25%;
                  }
                }

                .b2 {
                  width: 80%;
                  display: flex;
                  flex-direction: column;

                  .e1 {
                    height: 32%;
                    border-bottom: 1px solid black;
                    display: flex;

                    .f1 {
                      width: 15%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 5%;
                      }
                    }

                    .f2 {
                      width: 35%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 25%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 5%;
                      }
                    }

                    .f4 {
                      width: 25%;
                    }
                  }

                  .e2 {
                    height: 68%;
                    display: flex;

                    .f1 {
                      width: 15%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 20%;
                      }
                    }

                    .f2 {
                      width: 35%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 25%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f4 {
                      width: 25%;
                    }
                  }
                }
              }
            }
          }

          .row2 {
            width: 100%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c2 {
              width: 80%;
            }
          }

          .row3 {
            width: 100%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 15%;
              }
            }

            .c2 {
              width: 80%;
            }


          }



          .row5 {
            width: 100%;
            height: auto;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: auto;
              border-right: 1px solid black;


              p {
                text-align: center;
                margin-top: 15%;
              }
            }

            .d2 {
              width: 80%;
              height: auto;
              display: flex;


              .e1 {
                width: 100%;
              }

            }
          }


          .row10 {
            width: 100%;
            height: 18%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .e1 {
                width: 70%;
              }

              .e2 {
                width: 30%;
                display: flex;
                flex-direction: column;

                .sig {
                  width: 100%;
                  margin-bottom: 5px;
                }

              }

            }
          }


        }

      }
    }

  }
}