.all {
  width: 100%;
  height: 90vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .table {
          width: 90%;
          margin: 0 auto;
          height: 100%;

          .row1 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 4%;
              }
            }

            .a2 {
              width: 30%;
              border-right: 1px solid black;
            }

            .a3 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 4%;
              }
            }

            .a4 {
              width: 30%;
            }
          }

          .row2 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c2 {
              width: 80%;
              display: flex;

              .item {
                width: 40px;
              }

              p {
                margin-top: 4px;
              }
            }
          }

          .row3 {
            width: 100%;
            height: 13%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 20%;
              }
            }

            .c2 {
              width: 80%;
              display: flex;
              flex-wrap: wrap;

              .item {
                width: 40px;
              }

              p {
                margin-top: 4px;
                margin-right: 40px;
              }
            }


          }

          .row4 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 4%;
              }
            }

            .a2 {
              width: 80%;
            }

          }

          .row7 {
            height: 20%;
            width: 100%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .a2 {
              width: 80%;

              .e1 {
                width: 100%;
                height: 25%;
                border-bottom: 1px solid black;
                display: flex;

                .f1 {
                  width: 20%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 30%;
                  border-right: 1px solid black;

                }

                .f3 {
                  width: 20%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f4 {
                  width: 30%;

                }
              }

              .e2 {
                width: 100%;
                height: 25%;
                border-bottom: 1px solid black;
                display: flex;

                .f1 {
                  width: 20%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 30%;
                  border-right: 1px solid black;

                }

                .f3 {
                  width: 20%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f4 {
                  width: 30%;

                }
              }

              .e3 {
                width: 100%;
                height: 25%;
                border-bottom: 1px solid black;
                display: flex;

                .f1 {
                  width: 20%;
                  border-right: 1px solid #000;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 80%;
                }
              }

              .e4 {
                width: 100%;
                height: 25%;
                display: flex;

                .f1 {
                  width: 20%;
                  height: 100%;
                  border-right: 1px solid #000;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 80%;
                  display: flex;

                  .item {
                    width: 40px;
                  }

                  p {
                    margin-top: 4px;
                    margin-right: 50px;
                  }
                }
              }
            }
          }

          .row8 {
            height: 20%;
            width: 100%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .a2 {
              width: 80%;

              .e3 {
                width: 100%;
                height: 33%;
                border-bottom: 1px solid black;
                display: flex;

                .f1 {
                  width: 20%;
                  border-right: 1px solid #000;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 80%;
                }
              }

              .e4 {
                width: 100%;
                height: 33%;
                display: flex;

                .f1 {
                  width: 20%;
                  border-right: 1px solid #000;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 80%;
                }
              }

            }
          }

          .row6 {
            width: 100%;
            display: flex;
            border: 1px solid black;
            border-top: 0px;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 80%;
            }
          }

          .row5 {
            width: 100%;
            height: auto;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: auto;
              border-right: 1px solid black;


              p {
                text-align: center;
                margin-top: 15%;
              }
            }

            .d2 {
              width: 80%;
              height: auto;
              display: flex;


              .e1 {
                width: 100%;
              }

            }
          }


          .row10 {
            width: 100%;
            height: 18%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .e1 {
                width: 70%;
              }

              .e2 {
                width: 30%;
                display: flex;
                flex-direction: column;

                .sig {
                  width: 100%;
                  margin-bottom: 5px;
                }

              }

            }
          }

          .row11 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            display: flex;
            border-top: 0px solid #000;

            .d1 {
              width: 20%;
              border-right: 1px solid #000;

              p {
                text-align: center;
                margin-top: 8%;
              }

            }

            .d2 {
              width: 80%;
            }
          }
        }

      }
    }

  }
}