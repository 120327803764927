.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .form {
    width: 60%;
    height: 100%;
    margin: 0 auto;

    .btn {
      float: right;
    }

    h1 {
      text-align: center;
      font-size: 20px;
    }

    .item {
      width: 100%;
      margin: 5% auto;


    }
  }
}