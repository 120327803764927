 .all {
     width: 100%;
     height: 100%;
     display: flex;
     flex-wrap: wrap;
     overflow: auto;

     .table {
         width: 90%;
         margin: 1.7% auto;

         h2 {
             font-size: 1.2vw;
             text-align: center;
             font-weight: bolder;
         }

         :global {

             .ant-table-tbody>tr>td,
             .ant-table-thead>tr>th,
             .ant-table tfoot>tr>td,
             .ant-table tfoot>tr>th {
                 padding: 5px;
                 font-size: small;
             }


             .ant-table-thead>tr>th {
                 color: rgb(12, 12, 12);
                 background-color: rgba(63, 115, 131, 0.667);
             }

         }
     }

     .button {
         width: 100%;

         .allCahrt {
             float: right;
             border-radius: 15px;
             background-color: #106ebe;
             color: white;
             margin-right: 10%;
         }
     }


     .pic {
         margin-top: 2%;
         width: 100%;


         .pict {
             width: 100%;
         }
     }

 }