.all {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;

    .dele {
        width: 100%;
        height: 5%;

    }

    .form {
        width: 100%;
        margin: 0 auto;

        .item1 {
            margin-left: 0;
        }

        .item {
            margin: 0 35%;
            margin-right: 0;

            Input {
                width: 100%;
            }
        }
    }

    .img {
        margin: 0 auto;
        width: 20%;
        margin-top: 10%;

        .item {

            Input {
                width: 150px;
            }
        }

        :global {
            .ant-upload {
                width: 100%;
                height: 100%;
            }

        }
    }




}