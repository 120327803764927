.all {
  width: 100%;
  height: 100vh;
  overflow: hidden;


  .form {
    width: 80%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        border-bottom: 1px solid black;

      }

      .ant-input:focus {
        box-shadow: none;

      }

      .ant-form-item-label>label {
        font-weight: bolder;
      }
    }


    .table {
      width: 100%;
      height: 98%;
      overflow: auto;


      .center {
        width: 90%;
        height: 100%;
        margin: 0 auto;



        .time {
          display: flex;

          .item2 {
            width: 90%;
          }
        }

        .time1 {
          display: flex;

          .item {
            width: 50px;
          }
        }

        .check {
          display: flex;

        }



        .a1 {
          font-weight: bolder;
        }

        .item1 {
          width: 20%;
        }

        .textarea {
          border: 1px solid black;
        }








      }
    }


  }

}