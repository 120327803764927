 .all {
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: row;
   overflow: auto;
   background-image: url(../image/背景01.png);
   background-size: cover;

   .pic {
     width: 45%;
     height: 50%;
     margin-top: 3%;
     margin-left: 3%;

     .pict {
       width: 100%;
       height: 100%;
     }
   }

   .pic1 {
     width: 45%;
     height: 50%;
     margin-top: 3%;
     margin-left: 3%;

     .pict {
       width: 100%;
       height: 100%;
     }
   }

 }