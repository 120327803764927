.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .center {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 1% auto;

    :global {
      .ant-input {
        border: 0px solid black;
      }

      .ant-input:focus {
        box-shadow: none;
      }

      .ant-table-thead>tr>th {
        color: rgb(12, 12, 12);
        background-color: rgba(60, 173, 120, 0.667);
      }
    }


    .table {
      width: 100%;
      height: 40%;
      overflow-y: auto;
      margin: 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.257);
    }

    .table1 {
      width: 100%;
      height: 40%;

    }

    .add {
      width: 100%;
      float: right;
    }

    .add1 {
      float: right;
      margin-top: 2%;
      margin-right: 70px;
    }

    .add2 {
      margin-top: 2%;
      float: right;
    }


  }

}