.all {
    width: 100%;
    height: 100%;

    .center {
        width: 55%;
        height: 100%;
        overflow: auto;
        margin: auto;
        border: 3px solid rgba(188, 204, 193, 0.581);

        .form {
            width: 99%;
            height: 100%;
            overflow: auto;

            .ite1 {
                width: 100%;
                margin-left: 55px;
                margin-bottom: 3px;

                .input {
                    width: 80%;
                    border: 1px solid rgba(0, 0, 0, 0.582);
                }
            }

            h1 {

                margin-left: 40%;
            }


        }
    }

}