.all {
  width: 100%;
  height: 90vh;
  overflow: hidden;


  .form {
    width: 80%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;

    .list {
      width: 100%;

      .list1 {
        width: 100%;

        .item {
          width: 80%;

          Input {
            width: 100%;
          }
        }


        .list2 {
          width: 100%;
          display: flex;

          .item {
            width: 80%;

            Input {
              width: 100%;
            }
          }
        }



      }
    }

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        border-bottom: 1px solid black;

      }

      .ant-input:focus {
        box-shadow: none;

      }
    }

    .center {
      width: 100%;
      height: 100%;

      .list {
        width: 100%;

        .list1 {
          width: 100%;

          .page {
            width: 50%;
            text-align: center;
          }

          .context1 {
            width: 50%;
            display: flex;

            .item {
              width: 80%;

              Input {
                width: 100%;
              }
            }


          }


        }
      }

      .list2 {
        width: 100%;

        .list1 {
          width: 100%;

          .page {
            width: 15%;
            text-align: center;
          }

          .context1 {
            width: 70%;
            display: flex;

            .item {
              width: 80%;

              Input {
                width: 100%;
              }
            }


          }


        }
      }

      .list3 {
        width: 100%;

        .list1 {
          width: 100%;

          .page {
            width: 20%;
            text-align: center;
          }

          .context1 {
            width: 80%;
            display: flex;

            .item {
              width: 90%;

              Input {
                width: 100%;
              }
            }


          }


        }
      }

      .list {
        width: 100%;

        .list1 {
          width: 100%;

          .context {
            width: 80%;
            display: flex;

            .item {
              width: 80%;

              Input {
                width: 100%;
              }
            }


          }


        }
      }

      .textarea {
        border: 1px solid black;
      }

      .flex {
        display: flex;
        width: 100%;
        .select1 {
          width: 30%;
          margin-right: 5px;
        }

        p {
          font-weight: bolder;
        }

        .item1 {
          border-bottom: 1px solid black;
        }

        .item2 {
          width: 80%;
        }
        .item {
          width: 40%;
        }
        .item3 {
          width: 15%;
        }
      }
    }

  }

}