.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .center {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 0 auto;

    :global {
      .ant-input {
        border: 0px solid black;
      }

      .ant-input:focus {
        box-shadow: none;
      }
    }

    .table {
      width: 100%;
      height: 40%;
      overflow-y: auto;
      margin: 0 auto;
      border: 1px solid rgba(0, 0, 0, 0.257);
    }

    .form {
      width: 100%;
      height: 60%;

      .form1 {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        border: 1px solid black;

        .row1 {
          width: 100%;
          height: auto;
          border-bottom: 1px solid black;
          display: flex;

          .a0 {
            width: 5%;
            border-right: 1px solid black;

            p {
              text-align: center;
              margin-top: 30%;
            }
          }

          .a1 {
            width: 10%;
            border-right: 1px solid black;

            p {
              text-align: center;
              margin-top: 15%;
            }
          }

          .a2 {
            width: 10%;
            border-right: 1px solid black;

            p {
              text-align: center;
            }

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;
              display: flex;

              .c1 {
                width: 50%;
                height: 100%;
                border-right: 1px solid black;

                p {
                  text-align: center;
                }
              }

              .c2 {
                width: 50%;
                height: 100%;

                p {
                  text-align: center;
                }
              }
            }
          }

          .a3 {
            width: 15%;

            p {
              text-align: center;
            }

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;
              display: flex;

              .c1 {
                width: 50%;
                height: 100%;
                border-right: 1px solid black;

                p {
                  text-align: center;
                }
              }

              .c2 {
                width: 50%;
                height: 100%;

                p {
                  text-align: center;
                }
              }
            }
          }
        }

        .row2 {
          width: 100%;
          height: auto;
          border-bottom: 1px solid black;
          display: flex;

          .a0 {
            width: 5%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;

              p {
                text-align: center;
              }
            }
          }

          .a2 {
            width: 10%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              .item {
                margin-bottom: 0px;
              }

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;

              .item {
                margin-bottom: 0px;
              }

              p {
                text-align: center;
              }
            }
          }

          .a1 {
            width: 10%;
            border-right: 1px solid black;

            p {
              text-align: center;
              margin-top: 10%;
            }
          }

          .a4 {
            width: 10%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }

            .b2 {
              width: 100%;
              height: 50%;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }
          }

          .a3 {
            width: 15%;
            display: flex;

            .b1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-bottom: 0px;
              }
            }

            .b2 {
              width: 50%;

              .item {
                margin-bottom: 0px;
              }
            }
          }
        }

        .row3 {
          width: 100%;
          height: auto;
          border-bottom: 1px solid black;
          display: flex;

          .a0 {
            width: 5%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;

              p {
                text-align: center;
              }
            }
          }

          .a2 {
            width: 10%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              .item {
                margin-bottom: 0px;
              }

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;

              .item {
                margin-bottom: 0px;
              }

              p {
                text-align: center;
              }
            }
          }

          .a1 {
            width: 10%;
            border-right: 1px solid black;

            p {
              text-align: center;
              margin-top: 10%;
            }
          }

          .a4 {
            width: 10%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }

            .b2 {
              width: 100%;
              height: 50%;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }
          }

          .a3 {
            width: 15%;
            display: flex;

            .b1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-bottom: 0px;
              }
            }

            .b2 {
              width: 50%;

              .item {
                margin-bottom: 0px;
              }
            }
          }
        }

        .row4 {
          width: 100%;
          height: auto;
          border-bottom: 1px solid black;
          display: flex;

          .a0 {
            width: 5%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;

              p {
                text-align: center;
              }
            }
          }

          .a2 {
            width: 10%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;

              .item {
                margin-bottom: 0px;
              }

              p {
                text-align: center;
              }
            }

            .b2 {
              width: 100%;
              height: 50%;

              .item {
                margin-bottom: 0px;
              }

              p {
                text-align: center;
              }
            }
          }

          .a1 {
            width: 10%;
            border-right: 1px solid black;

            p {
              text-align: center;
              margin-top: 10%;
            }
          }

          .a4 {
            width: 10%;
            border-right: 1px solid black;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }

            .b2 {
              width: 100%;
              height: 50%;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }
          }

          .a3 {
            width: 15%;

            .b1 {
              width: 100%;
              height: 50%;
              border-bottom: 1px solid black;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }

            .b2 {
              width: 100%;
              height: 50%;
              display: flex;

              .c1 {
                width: 50%;
                border-right: 1px solid black;

                .item {
                  margin-bottom: 0px;
                }
              }

              .c2 {
                width: 50%;

                .item {
                  margin-bottom: 0px;
                }
              }

            }
          }
        }

        .row5 {
          width: 100%;
          display: flex;

          .a0 {
            width: 25%;
            border-right: 1px solid black;

            p {
              text-align: center;
            }
          }

          .a1 {
            width: 10%;
            border-right: 1px solid black;

            .item {
              margin-bottom: 0px;
            }
          }

          .a2 {
            width: 10%;
            border-right: 1px solid black;
            display: flex;

            .c1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-bottom: 0px;
              }
            }

            .c2 {
              width: 50%;

              .item {
                margin-bottom: 0px;
              }
            }
          }

          .a3 {
            width: 15%;
            display: flex;

            .c1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-bottom: 0px;
              }
            }

            .c2 {
              width: 50%;

              .item {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }

    .add {
      float: right;
    }
  }

}