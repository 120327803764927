.all {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(79, 78, 78, 0.201);

  .center {
    width: 95%;
    height: 90%;
    background-color: #fff;
    margin: 3% auto;
    overflow: hidden;

    .tool {
      width: 40%;
      height: 60%;
      margin: 10% auto;
      display: flex;
      flex-wrap: wrap;

      .tool1 {
        width: 25%;
        height: 25%;
        margin-right: 20px;
        text-align: center;

        .icon {
          font-size: 50px;
          color: rgb(89, 195, 65);
        }

        p {
          font-weight: 800;
          border-bottom: 1px solid black;
        }
      }

    }

  }
}