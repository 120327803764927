.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    h1 {
        font-size: 20px;
        text-align: center;
    }

    .form {
        width: 40%;
        height: 80%;
        margin: 2% auto;
        border: 1px solid rgba(60, 59, 58, 0.21);

        .item {
            width: 80%;
            margin-left: 10%;

            Input {
                border: 1px solid rgba(0, 0, 0, 0.572);
            }
        }

        .btn {
            width: 80px;
            float: right;
            margin-right: 10%;
        }

        .btn1 {
            width: 80px;
            margin-left: 23%;
        }

    }
}