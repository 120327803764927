.table {
    :global {

        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: rgba(63, 131, 78, 0.667);
        }

    }

    .btn {
        margin-right: 10px;
    }

    .for {
        background-color: rgb(22, 182, 124);
    }
}