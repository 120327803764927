.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .center {
    width: 100%;
    height: 100%;
    overflow: auto;
    margin: 1% auto;

    :global {
      .ant-table-thead>tr>th {
        color: rgb(12, 12, 12);
        background-color: rgba(60, 132, 173, 0.667);
      }

    }

    .uplode {
      width: 50%;
      height: 80%;
      float: left;

      p {
        font-size: 25px;
        text-align: center;
      }

      .uploadFile {
        width: 80%;
        max-height: 80%;
        overflow-y: auto;
      }

      .addFile {
        width: 100px;
        margin-top: 40px;
        float: right;
        margin-right: 2%;
      }
    }

    .table {
      width: 50%;
      height: 80%;
      overflow-y: auto;
      margin: 0 auto;
      float: right;
      border: 1px solid rgba(0, 0, 0, 0.257);
    }

    .add {
      float: right;
      margin-top: 2%;
      margin-right: 2%;
    }
  }

}