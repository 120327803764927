.center {
    width: 100%;
    height: 100%;
    background-color: rgba(163, 214, 205, 0.229);

    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 10px;
        }


        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: rgba(80, 145, 175, 0.626);
        }

    }

    .table {
        width: 98%;
        height: 80%;
        margin: auto;
    }
}