.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .info {
        width: 70%;
        height: 100%;
        float: right;

        .ta {
            width: 95%;
            height: 80%;

            .form {
                margin-top: 10px;
                height: 5.5%;

                .icon {
                    color: rgb(38, 200, 48);
                }
            }
        }

        .moType {
            width: 70%;
            height: 40%;

            .ite {
                text-align: right;

                Button {
                    margin-left: 20px;
                }

            }

            h1 {
                font-size: 20px;
                text-align: center;
            }

            .moform {
                width: 100%;
            }

        }

        .aes {
            width: 100%;
            height: 5%;
            margin-top: 1%;
            overflow: hidden;
            border-bottom: 1px solid slategrey;

            .mb {
                height: 100%;
                width: 50%;
                margin-left: 1%;

                input {
                    height: 25px;
                    width: 50%;
                    border-radius: 3px;
                    border: 1px solid rgb(123, 144, 142);
                }
            }
        }
    }

    .tree {
        width: 30%;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.473);
        float: left;


        :global {
            .ant-list-item-meta-title {
                font-size: 10px;
                font-weight: bolder;
            }

            .ant-list-split .ant-list-item {
                border-bottom: 1px solid rgba(114, 122, 118, 0.47);
            }
        }
    }

}