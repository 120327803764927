.all {
  width: 100%;
  height: 100vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }


  .form {
    width: 100%;
    height: 120%;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .casenumber {
          width: 40%;
          margin-left: 50%;

          .item {
            width: 70%;

            Input {
              text-align: center;
              border: 0px;
              border-bottom: 1px solid black;
            }
          }
        }

        .text {
          width: 60%;
          margin: 0 auto;

          .textsig {
            width: 100%;
            display: flex;

            .date {
              width: 100%;
            }

            .time {
              border-bottom: 1px solid black;
            }

            .itemtime {
              width: 40%;
            }
          }

          .item1 {
            width: 22%;

            Input {
              border-bottom: 1px solid black;
            }
          }

          .a1 {
            font-weight: bolder;
          }

          .textarea {
            border: 1px solid black;
          }
        }
      }
    }

  }
}