.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .form {
        width: 38%;
        height: 70%;
        margin: 5% auto;
        border: 1px solid rgba(0, 0, 0, 0.321);

        h1 {
            text-align: center;
            font-size: 20px;
            margin-bottom: 30px;
        }

        .ite {
            width: 70%;
            margin-left: 10%;

        }


        .btn {
            float: right;
        }

    }
}