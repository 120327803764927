.all {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .btn {
    margin-right: 10%;
  }

  .form {
    width: 90%;
    height: 100%;
    overflow: auto;
    margin: 0 auto;

    .textsig {
      width: 100%;
      display: flex;
      margin-top: 5px;

      .select {
        width: 30%;
      }
    }

    .btn {
      width: 10%;
      margin-top: 0;
      float: right;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        text-align: center;

      }

      .ant-input:focus {
        box-shadow: none;

      }
    }

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .center {
      float: left;
      width: 100%;
      height: 100%;

      .top {
        display: flex;
        flex-direction: row;
        width: 100%;

        .top1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 5%;
          border: 1px solid black;
          margin-left: 5%;
        }

        .top2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.5%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top4 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.5%;
          border: 1px solid black;
          border-left: 0px solid black;
        }



        .top6 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top7 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 15%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top8 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.5%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top9 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.5%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

      }

      .buttom {
        display: flex;
        flex-direction: row;
        width: 100%;

        .buttom1 {
          width: 5%;
          border: 1px solid black;
          border-top: 0px solid black;
          margin-left: 5%;
        }

        .buttom2 {
          width: 15%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom3 {
          width: 7.5%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom4 {
          width: 7.5%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom6 {
          width: 15%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom7 {
          width: 15%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom8 {
          width: 7.5%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom9 {
          width: 7.5%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom10 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7.5%;
          height: 100%;
        }


      }

      .sig {
        display: flex;

        p {
          font-weight: bold;
        }
      }


    }
  }

}