.all {
    width: 100%;
    height: 100%;

    h1 {
        font-size: 1.3vw;
    }

    h2 {
        font-size: 1.1vw;
    }

    .cen {
        width: 70%;
        height: 100%;
        margin: auto;
        overflow: scroll;
        background-color: rgb(138, 109, 53);
        text-align: center;

        input {
            width: 300px;
            height: 37px;
            border: 0px;
            border-bottom: 1px solid black;
            background-color: rgba(255, 255, 255, 0);
            margin-bottom: 2px;
        }

        input:focus {
            outline: none;
        }

        .label {
            vertical-align: top;
        }

        .text {
            width: 300px;
            min-height: 85px;
            max-height: 500px;
            border: 0px;
            border-bottom: 1px solid black;
            background-color: rgba(255, 255, 255, 0);
            margin-right: 126px;
            resize: none;
            outline: none;
        }

        .label1 {
            vertical-align: top;
        }

        .text1 {
            width: 300px;
            height: 50px;
            border: 0px;
            border-bottom: 1px solid black;
            background-color: rgba(255, 255, 255, 0);
            margin-right: 114px;
            resize: none;
            outline: none;
        }



        .label1 {
            input {
                margin-right: 113px;
            }
        }
    }
}