.all {
    width: 100%;
    height: 100%;
    background-color: rgba(99, 97, 97, 0.259);
    overflow: hidden;

    .center {
        width: 98%;
        height: 100%;
        background-color: #fff;
        margin: 1% auto;
        padding: 2%;

        .form .colour {
            height: 30px;
            background-color: rgba(146, 190, 222, 0.468);
            border: 1px solid rgba(146, 190, 222, 0.468);
            margin-right: 5px;
            margin-bottom: 10px;
        }

        h1 {
            font-size: 25px;
            border-bottom: 1px solid black;
        }

        .tree {
            width: 100%;
            margin: auto;

        }
    }



}