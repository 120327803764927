.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .btn {
    margin-right: 10%;
  }

  .form {
    width: 90%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;

    .textsig {
      width: 100%;
      display: flex;
      margin-top: 5px;

      .select {
        width: 30%;
      }
    }

    .btn {
      width: 10%;
      margin-top: 0;
      float: right;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        text-align: center;

      }

      .ant-input:focus {
        box-shadow: none;

      }
    }

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .center {
      float: left;
      width: 100%;
      height: 100%;
      overflow: auto;

      .top {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 5%;

        .top1 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: 100%;
          border: 1px solid black;
        }

        .top2 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60%;
          height: 100%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top3 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: 100%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top4 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: 100%;
          border: 1px solid black;
          border-left: 0px solid black;
        }

        .top5 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: 100%;
        }


      }

      .buttom {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 5%;


        .buttom1 {
          width: 10%;
          height: 100%;
          border: 1px solid black;
          border-top: 0px solid black;
        }

        .buttom2 {
          width: 60%;
          height: 100%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom3 {
          width: 10%;
          height: 100%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom4 {
          width: 10%;
          height: 100%;
          border: 1px solid black;
          border-top: 0px solid black;
          border-left: 0px solid black;
        }

        .buttom5 {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: 100%;
        }



      }



    }
  }

}