.all {
    height: 100%;
    width: 100%;
    overflow: hidden;

    .tops {
        width: 100%;
        height: 10%;
        overflow: hidden;
        background-color: #254268;

        h1 {
            text-align: center;
            font-size: 1.4vw;
            margin-top: 10%;
            color: white;
        }
    }

    .tabs {
        height: 80%;
        margin-left: 2%;

        .tabphone {
            height: calc(90vh);

            .commit {
                width: 95%;
                margin: 0 auto;

                .btn {
                    margin-top: 5px;
                }

                .text {
                    border-top: 1px solid rgba(0, 0, 0, 0.444);
                    width: 100%;
                }
            }
        }

        .centers {
            height: 80%;
            width: 100%;
            overflow-y: auto;
        }


    }


}






:global {
    .ant-comment-content-author-name {
        color: rgb(7, 7, 7);
        font-size: 16px;
        font-weight: bolder;
    }

    .ant-comment-content-author-time {
        color: rgba(8, 8, 8, 0.822);
        font-size: 4px;
        margin-top: 2px;
    }

    .ant-comment-content {
        font-size: 14px;
    }



}

.span {
    display: block;
    width: 80%;
    height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



.centers::-webkit-scrollbar {
    width: 10px;
}

.centers::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(14, 15, 14, 0.4);
}