.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    Button {
        float: right;
        margin-right: 1%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .list {
        width: 98%;
        height: 90%;
        margin: 0% auto;
        overflow: auto;
    }
}