.all {
  width: 100%;
  height: 100vh;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }


  .form {
    width: 100%;
    height: 100%;
    overflow: auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .casenumber {
          width: 20%;
          display: flex;
          flex-direction: row;
          margin-left: 60%;

          .item {
            width: 20%;

            Input {
              border: 0px;
              border-bottom: 1px solid black;
            }
          }
        }

        .table {
          width: 70%;
          height: 90%;
          margin: 0 auto;

          .row1 {
            height: 7%;
            border: 1px solid black;
            border-bottom: 0px;

            .item {
              text-align: center;
              width: 100%;
              margin: 0 auto;

              Input {
                text-align: center;
              }
            }
          }

          .row2 {
            border: 1px solid black;
            border-bottom: 0px;
            height: 6%;
            text-align: center;
          }

          .row3 {
            height: 6%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;
              text-align: center;

              p {
                margin-top: 5px;
              }
            }

            .a2 {
              width: 80%;
              display: flex;

              .item {
                width: 80%;
                margin-left: 10%;

                Input {
                  text-align: center;
                }

              }
            }
          }

          .row4 {
            height: 6%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;
              text-align: center;

              p {
                margin-top: 5px;
              }
            }

            .a2 {
              width: 80%;

              .item {
                width: 100%;

                Input {
                  width: 100%;
                  text-align: center;
                }
              }

            }
          }

          .row5 {
            height: 6%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;
              text-align: center;

              p {
                margin-top: 5px;
              }
            }

            .a2 {
              width: 80%;

              .item {
                width: 100%;

                Input {
                  width: 100%;
                  text-align: center;
                }
              }

            }
          }

          .row6 {
            height: 6%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;
              text-align: center;

              p {
                margin-top: 5px;
              }
            }

            .a2 {
              width: 80%;

              .item {
                width: 100%;

                Input {
                  width: 100%;
                  text-align: center;
                }
              }

            }
          }

          .row7 {
            height: 7%;
            display: flex;
            border: 1px solid black;
            border-bottom: 0px;

            .a1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-left: 10px;
                margin-top: 2px;
              }
            }

            .a2 {
              width: 50%;

              .item {
                margin-left: 10px;
                margin-top: 5px;
              }
            }
          }

          .row8 {
            height: 8%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-left: 10px;
                margin-top: 2px;
              }

            }

            .a2 {
              width: 50%;
              display: flex;

              Input {
                border-bottom: 1px solid black;
              }

            }
          }

          .row9 {
            flex-grow: 1;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 13px;
              }
            }

            .a2 {
              width: 80%;
            }
          }

          .row10 {
            height: auto;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .a2 {
              width: 80%;
            }
          }

          .row11 {
            height: 15%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .a2 {
              width: 80%;
            }
          }

          .row12 {
            height: 7%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 50%;
              border-right: 1px solid black;

              .item {
                margin-left: 10px;
                margin-top: 5px;
              }
            }

            .a2 {
              width: 50%;

              .item {
                margin-left: 10px;
                margin-top: 5px;
              }
            }
          }

          .row13 {
            height: 7%;
            border: 1px solid black;
            display: flex;

            .a1 {
              width: 50%;
              border-right: 1px solid black;
              display: flex;

              .item {
                width: 30%;
                margin-left: 10px;
                margin-top: 5px;

                Input {
                  border-bottom: 1px solid black;
                }
              }
            }

            .a2 {
              width: 50%;

              .item {
                margin-left: 10px;
                margin-top: 5px;
              }
            }
          }



        }

      }
    }

  }
}