.center {
    width: 100%;
    height: 100%;

    .list {
        background-color: #fff;
        overflow: hidden;

        .item {
            width: 150px;
            margin-right: 40px;
            margin-top: 10%;
            background-color: rgb(230, 156, 156);
        }
    }

    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 8px;
        }


        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: rgba(153, 154, 151, 0.667);
        }

    }

    .table {
        width: 98%;
        height: 80%;
        margin: auto;
    }




}