.cen {
    width: 100%;
    height: 100%;

    .form {
        width: 60%;
        height: 70%;
        margin: auto;
        background-color: rgba(179, 175, 175, 0.176);
        margin-top: 10%;
        overflow: hidden;


        .item {
            width: 100%;
            height: 9%;
            margin-top: 10%;
        }

        .inp {
            margin-top: 60px;
        }

    }
}