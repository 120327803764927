.all {
    width: 100%;
    height: 100%;
    overflow: hidden;


    .center {
        width: 60%;
        height: 60%;
        overflow: hidden;
        margin: 5% auto;
        background-color: rgba(164, 162, 162, 0.662);

        .form {
            width: 98%;
            margin: 1% auto;
            height: 97%;
            padding: 3%;
            background-color: #fff;
            border: 1px solid rgba(57, 147, 212, 0.753);

            .item {
                width: 45%;
                margin-top: 10px;
                margin-left: 4%;
                margin-bottom: 10px;

                .ipt {
                    height: 40px;
                    border: 1px solid rgb(152, 185, 181);
                    border-radius: 5px;
                }
            }

            .item1 {
                width: 100%;

                .ipt {
                    width: 98%;
                    height: 40px;
                    border-radius: 5px;
                    border: 1px solid rgb(152, 185, 181);
                }
            }

        }

        .btn {
            width: 100px;
            margin-left: 17%;
        }
    }
}