.all {
    width: 100%;
    height: 100%;

    .cen {
        width: 100%;
        height: 95.5%;
    }

    .btnt {
        width: 100%;
        height: 4.5%;
        background-color: rgba(155, 171, 165, 0.119);

        .hbtn {
            width: 120px;
            height: 100%;
            color: black;
            border-right: 1px solid rgba(20, 20, 19, 0.333);
            border-bottom: 1px solid rgba(20, 20, 19, 0.133);

        }
    }

    .form {
        white-space: nowrap;
        width: 100%;
        height: 70%;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid rebeccapurple;



        .ite {
            width: 70%;
            display: inline-block;

            .select {
                width: 100%;
            }

            .input {
                width: 100%;
                border-radius: 4px;
                margin-left: 3px;
                border: 1px solid rgba(0, 0, 255, 0.317);
            }
        }

        :global {
            .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                border-radius: 4px;
                border: 1px solid rgba(0, 0, 255, 0.317);
            }
        }

        .hea {
            width: 100%;
            height: 30px;
            margin-bottom: 10px;
            background-color: rgba(137, 178, 203, 0.527);
        }

        .head {
            width: 100%;
            height: 30px;
            margin-bottom: 10px;
            background-color: rgba(137, 178, 203, 0.527);
        }



        .item1 {
            width: 70%;

            .input {
                width: 100%;
                border-radius: 4px;
                margin-left: 3px;
                border: 1px solid rgba(0, 0, 255, 0.317);
            }

            label {
                margin-left: 4px;
            }

            .select {
                width: 100%;
            }

        }

        .item2 {
            width: 70%;

            .select {
                width: 100%;
            }


        }



        .item3 {
            width: 100%;
            height: 25%;

            TextArea {
                width: 95%;
                height: 75%;
                margin-top: 1%;
                margin-left: 2.5%;
                border-radius: 5px;
                border: 1px solid rgb(61, 52, 218);
            }

        }

        .btn {
            margin-left: 26px;
        }

        .lab {
            display: inline-block;
            margin-top: 4px;
        }

        .form {
            white-space: nowrap;
            width: 100%;
            height: 70%;
            overflow-y: auto;
            overflow-x: hidden;
            border-bottom: 1px solid rebeccapurple;

            .ite {
                width: 70%;
                display: inline-block;

                .select {
                    width: 100%;
                }

                .input {
                    width: 100%;
                    border-radius: 4px;
                    margin-left: 3px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }
            }

            :global {
                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

            }





            .item1 {
                width: 70%;

                .input {
                    width: 100%;
                    border-radius: 4px;
                    margin-left: 3px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                label {
                    margin-left: 4px;
                }

                .select {
                    width: 100%;
                }

            }

            .item2 {
                width: 70%;

                .select {
                    width: 100%;
                }

            }

            .item3 {
                width: 100%;
                height: 25%;

                TextArea {
                    width: 95%;
                    height: 75%;
                    margin-top: 1%;
                    margin-left: 2.5%;
                    border-radius: 5px;
                    border: 1px solid rgb(61, 52, 218);
                }

            }
        }

        .form::-webkit-scrollbar {
            width: 4px;
        }

        .form::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(88, 183, 226, 0.2);
        }




        .item1 {
            width: 70%;

            .input {
                width: 100%;
                border-radius: 4px;
                margin-left: 3px;
                border: 1px solid rgba(0, 0, 255, 0.317);
            }

            label {
                margin-left: 4px;
            }

            .select {
                width: 100%;
            }

        }

        .item2 {
            width: 70%;

            .select {
                width: 100%;
            }

        }

        .item3 {
            width: 100%;
            height: 25%;

            TextArea {
                resize: none;
                width: 95%;
                height: 75%;
                margin-top: 1%;
                margin-left: 2.5%;
                border-radius: 5px;
                border: 1px solid rgb(61, 52, 218);
            }

        }

        .form::-webkit-scrollbar {
            width: 4px;
        }

        .form::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(88, 183, 226, 0.2);
        }




    }

    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 5px;
        }

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 5px;
        }


        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: rgb(198, 205, 196);
        }

    }

    .list {
        width: 100%;
        height: 30%;

        .listtable {
            width: 100%;
            height: 100%;
            overflow: auto;

            .TabPane {
                width: 100%;
                height: 100%;

                Button {
                    width: 80px;
                    border: px;
                    background-color: rgba(68, 145, 157, 0.144);
                    margin-top: -5px;
                }




            }
        }

    }
}


.btn {
    margin-left: 26px;
}

.lab {
    display: inline-block;
    margin-top: 4px;
}