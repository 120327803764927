.all {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .center {
    width: 100%;
    height: 100%;
    overflow: auto;

    .title {
      text-align: center;
      font-size: 30px;
      font-weight: bolder;
    }

    .directory {
      width: 30%;
      float: left;
      padding: 20px;
      display: flex;
      flex-direction: column;

      a {
        text-decoration: none;
        color: #3a3535;
        margin-bottom: 10px;
        transition: background-color 0.3s ease;
      }

      a:hover {
        background-color: #f2f2f2;
        border-radius: 5px;
      }

      a span {
        font-size: 20px;
        display: inline-block;
        padding-left: 10px;
      }

      a:before {
        margin-right: 5px;
        font-size: 14px;
      }
    }



    .content {
      width: 70%;
      float: right;

      .action1 {
        width: 100%;
        margin-bottom: 20px;

        .subtitle {
          font-size: 20px;
          font-weight: bolder;
        }

        .text {}
      }

      .action2 {
        width: 100%;
        margin-bottom: 20px;

        .subtitle {
          font-size: 20px;
          font-weight: bolder;
        }

        .pban {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          gap: 20px; // 调整步骤之间的间距

        }

        .text {}
      }
    }
  }
}