.all {
  width: 100%;
  height: 100vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }



  .form {
    width: 100%;
    height: 120%;



    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;


        .case1 {
          width: 60%;
          display: flex;
          margin: 0 auto;

          .casenumber {
            width: 45%;
            display: flex;

            p {
              font-weight: bold;
            }

            .item {
              width: 60%;

              Input {
                border: 0px;
                border-bottom: 1px solid black;
              }
            }
          }
        }


        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;



          .row3 {
            height: 7%;
            width: 100%;
            border: 1px solid black;
            display: flex;

            .a1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 80%;
              height: 100%;
            }
          }

          .row4 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;

            .d1 {
              width: 20%;
              border-right: 1px solid black;


              p {
                text-align: center;
                margin-top: 8%;
              }
            }

            .d2 {
              width: 30%;
              border-right: 1px solid black;
            }

            .d3 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 8%;
              }
            }

            .d4 {
              width: 30%;
            }
          }

          .row7 {
            height: 8%;
            width: 100%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;

            .a1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 80%;
              height: 100%;
            }
          }

          .row5 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 20%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .time {
                width: 32%;
                height: 100%;

                .select1 {
                  width: 200px;
                  margin-bottom: 2px;
                }

              }




            }
          }

          .row6 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 50%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;

              .input {
                width: 68%;
                height: 100%;
                float: left;

                .text {
                  margin-top: 8%;
                }
              }

              .input1 {
                display: flex;
                width: 40%;

                .item {
                  width: 65%;
                }

                Input {
                  border-bottom: 1px solid black;
                }
              }

              .input2 {
                display: flex;
                width: 100%;

                p {
                  font-size: small;
                }
              }

              .time {
                width: 32%;
                height: 100%;
                float: right;

                .select1 {
                  width: 150px;
                  margin-bottom: 0px;
                }

              }

            }
          }

        }

      }
    }

  }
}