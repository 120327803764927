.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .table {
        width: 98%;
        height: 95%;
        margin: 1% auto;
        overflow: auto;
    }

    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 4px;
        }

        .row-dragging {
            background-color: #378a9c45;
        }

        .row-dragging td {
            padding: 16px;
        }

        .row-dragging .drag-visible {
            visibility: visible;
        }

        .ant-table-thead>tr>th {
            background-color: rgba(203, 189, 68, 0.447);
        }
    }
}