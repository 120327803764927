.all {
    width: 100%;
    height: 100%;

    .center {
        width: 55%;
        height: 100%;
        float: left;
        overflow: auto;
        border: 1px solid black;

        .form {
            width: 99%;
            height: 100%;
            overflow: auto;

            .ite1 {
                margin-left: 10%;
                margin-bottom: 3px;
                width: 75%;

                .input {
                    width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.582);
                }
            }

            h1 {
                margin-left: 40%;
            }

        }
    }

    .reason {
        width: 45%;
        height: 100%;
        float: right;


        .f1 {
            width: 80%;
            height: 100%;
            margin: auto;
            overflow: hidden;
            text-align: center;

            .sb {
                width: 100%;

                .sbtn1 {
                    width: 100px;
                    height: 35px;
                    margin-left: 30%;
                }

                .sbtn {
                    width: 100px;
                    height: 35px;

                }
            }

            h2 {
                font-size: 1.8vw;
                margin-top: 8%;
            }

            .test {
                border: 1px solid black;
            }



        }
    }

}