.all {
  width: 100%;
  height: 90vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .table {
          width: 90%;
          margin: 0 auto;
          height: 100%;

          .row1 {
            width: 100%;
            height: 40%;
            border: 1px solid black;

            .clumn1 {
              width: 20%;
              height: 100%;
              float: left;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 100%;
              }
            }

            .clumn2 {
              width: 80%;
              height: 100%;
              float: right;
              display: flex;
              flex-direction: column;

              .a1 {
                height: 50%;
                width: 100%;
                display: flex;
                border-bottom: 1px solid black;

                .b1 {
                  width: 20%;
                  border-right: 1px solid black;
                  display: flex;

                  .item {
                    width: 40px;
                    height: 50px;
                    margin-top: 45%;
                  }

                  p {
                    margin-top: 50%;
                  }
                }

                .b2 {
                  width: 80%;
                  display: flex;
                  flex-direction: column;

                  .e1 {
                    height: 30%;
                    border-bottom: 1px solid black;
                    display: flex;

                    .f1 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f2 {
                      width: 20%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f4 {
                      width: 20%;
                      border-right: 1px solid black;


                    }

                    .f5 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f6 {
                      width: 20%;

                    }
                  }

                  .e2 {
                    height: 70%;
                    display: flex;

                    .f1 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 25%;
                      }
                    }

                    .f2 {
                      width: 20%;
                      border-right: 1px solid black;

                      .item {
                        margin-top: 10%;
                      }
                    }

                    .f3 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 25%;
                      }
                    }

                    .f4 {
                      width: 20%;
                      border-right: 1px solid black;

                      .item {
                        margin-top: 10%;
                      }
                    }

                    .f5 {
                      width: 13%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 25%;
                      }
                    }

                    .f6 {
                      width: 20%;

                      .item {
                        margin-top: 10%;
                      }
                    }
                  }
                }
              }

              .a2 {
                height: 50%;
                width: 100%;
                display: flex;

                .b1 {
                  width: 20%;
                  border-right: 1px solid black;
                  display: flex;

                  .item {
                    width: 70px;
                    height: 50px;
                    margin-top: 36%;
                  }

                  p {
                    margin-top: 40%;
                  }
                }

                .b2 {
                  width: 80%;

                  .e1 {
                    height: 40%;
                    border-bottom: 1px solid black;
                    display: flex;

                    .f1 {
                      width: 15%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f2 {
                      width: 35%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 25%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f4 {
                      width: 25%;
                    }
                  }

                  .e2 {
                    height: 60%;
                    display: flex;

                    .f1 {
                      width: 15%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f2 {
                      width: 35%;
                      border-right: 1px solid black;
                    }

                    .f3 {
                      width: 25%;
                      border-right: 1px solid black;

                      p {
                        text-align: center;
                        margin-top: 10%;
                      }
                    }

                    .f4 {
                      width: 25%;
                    }
                  }
                }
              }
            }
          }

          .row2 {
            width: 100%;
            height: 6%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c2 {
              width: 30%;
              border-right: 1px solid black;
            }

            .c3 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c4 {
              width: 30%;
              display: flex;

            }

          }

          .row3 {
            width: 100%;
            height: 6%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c2 {
              width: 30%;
              border-right: 1px solid black;
            }

            .c3 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c4 {
              width: 30%;
              text-align: center;
            }
          }

          .row4 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c2 {
              width: 40%;
              border-right: 1px solid black;

              .casenumber {
                margin-top: 2%;
                display: flex;

                .item1 {
                  width: 100%;

                  Input {
                    text-align: center;
                  }
                }

                .item {
                  width: 40%;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }
              }
            }

            .c3 {
              width: 15%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 7%;
              }
            }

            .c4 {
              width: 25%;
              text-align: center;

              .item {
                margin-top: 4%;
              }
            }
          }

          .row5 {
            width: 100%;
            height: auto;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;

              p {
                text-align: center;
                margin-top: 15%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;
              border-left: 1px solid black;

              .e1 {
                width: 100%;
              }

            }
          }

          .row6 {
            width: 100%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;

              p {
                text-align: center;
                margin-top: 15%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;
              border-left: 1px solid black;

              .list {
                width: 100%;

                .list1 {
                  width: 100%;

                  .page {
                    width: 13%;

                    Input {
                      width: 100%;
                      border-bottom: 1px solid;
                    }
                  }

                  .context1 {
                    width: 80%;
                    display: flex;

                    .item {
                      width: 80%;

                      Input {
                        width: 100%;
                        border-bottom: 1px solid;
                      }
                    }


                  }


                }
              }

            }
          }

          .row7 {
            width: 100%;
            height: 40%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 85%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;

              .e1 {
                width: 100%;
                height: 25%;
                display: flex;
                margin-bottom: 0;

                .item {
                  width: 40px;
                  height: 60px;
                }

                p {
                  margin-top: 5px;
                }

                .item1 {
                  width: 50%;
                  height: 10%;
                  margin-top: 3px;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }

                .item2 {
                  width: 30%;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }
              }

              .e2 {
                width: 100%;
                height: 25%;
                display: flex;
                margin-bottom: 0;

                .item {
                  width: 40px;
                  height: 60px;
                }

                p {
                  margin-top: 5px;
                }

                .item1 {
                  width: 50%;
                  height: 10%;
                  margin-top: 3px;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }

                .item2 {
                  width: 35%;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }
              }



            }
          }

          .row8 {
            width: 100%;
            height: 40%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 85%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;

              .e1 {
                width: 100%;
                height: 25%;
                display: flex;
                margin-bottom: 0;

                .item {
                  width: 40px;
                  height: 60px;
                }

                p {
                  margin-top: 5px;
                }

                .item1 {
                  width: 40%;
                  height: 10%;
                  margin-top: 3px;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }

                .item2 {
                  width: 30%;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }
              }

              .e2 {
                width: 100%;
                height: 25%;
                display: flex;
                margin-bottom: 0;

                .item {
                  width: 40px;
                  height: 60px;
                }

                p {
                  margin-top: 5px;
                }

                .item1 {
                  width: 50%;
                  height: 10%;
                  margin-top: 3px;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }

                .item2 {
                  width: 25%;

                  Input {
                    border-bottom: 1px solid black;
                  }
                }
              }



            }
          }

          .row9 {
            width: 100%;
            height: 10%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .e1 {
                width: 100%;
              }

            }
          }

          .row10 {
            width: 100%;
            height: 18%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .e1 {
                width: 70%;
                border-right: 1px solid black;
              }

              .e2 {
                width: 30%;
                display: flex;
                flex-direction: column;

                .sig {
                  width: 97%;
                  margin-left: 2%;
                  margin-bottom: 5px;
                }

                .time {
                  margin-bottom: 5px;
                }


              }

            }
          }

        }

      }
    }

  }
}