.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    h1 {
        font-size: 20px;
        text-align: center;
    }

    .card {
        width: 40%;
        margin: 0 auto;
        height: 100%;
        overflow: hidden;

        .form {
            width: 100%;
            height: 80%;
            margin: 2% auto;
            border: 1px solid rgba(60, 59, 58, 0.21);

            .item {
                width: 70%;
                margin-left: 10%;

                Input {
                    border: 1px solid rgba(0, 0, 0, 0.572);
                }
            }

            .btn {
                width: 80px;
                float: right;
                margin-right: 19%;
            }

        }
    }


}