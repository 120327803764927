.all {
  width: 100%;
  height: 100vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }



  .form {
    width: 100%;
    height: 120%;



    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;





        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;



          .row3 {
            height: 5%;
            width: 100%;
            border: 1px solid black;
            display: flex;

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 75%;
              height: 100%;
            }
          }

          .row4 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 25%;
              border-right: 1px solid black;


              p {
                text-align: center;
                margin-top: 4%;
              }
            }

            .d2 {
              width: 75%;
            }

            .d3 {
              width: 75%;
              display: flex;
              .input{
                width: 100%;
              }
            }
          }

          .row5 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 4%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;

              .time {
                width: 100%;
                display: flex;

                p {
                  margin-top: 5px;
                }

                .item {
                  width: 60px;
                }


              }




            }

          }

          .row6 {
            width: 100%;
            height: 15%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 20%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;
              display: flex;

              .input {
                width: 68%;
                height: 100%;
                display: flex;
                float: left;

                .item {
                  width: 60px;
                }

                p {
                  margin-top: 4px;
                }
              }

              .time {
                width: 30%;
                height: 100%;

                .select1 {
                  width: 130px;
                  margin-bottom: 0px;
                }

              }

            }
          }

        }

      }
    }

  }
}