.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #09c2f517;
    margin: 0 auto;

    .table {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: auto;
    }

    .addCase {
        margin-top: 1%;
        margin-left: 2px;
        background-color: #0d4a9980;
        border: 1px solid rgba(89, 155, 106, 0.374);
    }

    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 4px;
            font-size: small;
        }


        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: #0d4999a9;
        }

    }
}