.all {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: rgba(138, 135, 135, 0.126);

    :global {
        .ant-tree .ant-tree-node-content-wrapper {
            text-align: center;
        }
    }

    .cen {
        width: 98%;
        height: 100%;
        margin: 1% auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: #fff;

        .ser {
            margin-top: 2%;
            margin-bottom: 2%;
            margin-left: 2%;

            .inp {
                width: 20%;
                margin-right: 1%;

            }

            .btn {
                width: 60px;
            }
        }

        .list {
            margin-top: 20px;
            width: 100%;
            height: 100%;
            overflow: auto;

            h1 {
                font-size: 25px;
                border-bottom: 1px solid black;
            }

            .form {
                height: 30px;
                margin-bottom: 5px;


                Button {
                    width: 80px;
                    background-color: rgba(146, 190, 222, 0.468);
                    margin-right: 5px;
                    height: 90%;
                }
            }




        }
    }
}