.all {
  width: 100%;
  height: 100vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }



  .form {
    width: 100%;
    height: 120%;



    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;



        .casenumber {
          width: 30%;
          display: flex;
          flex-direction: row;
          margin-left: 40%;

          .item {
            width: 70%;

            Input {
              text-align: center;
              border: 0px;
              border-bottom: 1px solid black;
            }
          }
        }

        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;



          .row3 {
            height: 5%;
            width: 100%;
            border: 1px solid black;
            display: flex;

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 75%;
              height: 100%;
            }
          }

          .row4 {
            width: 100%;
            height: auto;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 25%;
              height: auto;
              border-right: 1px solid black;


              p {
                text-align: center;
                margin-top: 8%;
              }
            }

            .d2 {
              width: 75%;
              height: auto;

              .list {
                width: 100%;

                .list1 {
                  width: 100%;

                  .page {
                    width: 10%;
                  }

                  .context1 {
                    width: 80%;
                    display: flex;

                    .item {
                      width: 80%;

                      Input {
                        width: 100%;
                      }
                    }


                  }


                }
              }
            }
          }

          .row5 {
            width: 100%;
            height: 15%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 20%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;
              display: flex;

              .time {
                width: 32%;
                height: 100%;

                .select1 {
                  width: 200px;
                  margin-bottom: 2px;
                }

              }




            }
          }

          .row6 {
            width: 100%;
            height: 12%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 20%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;

              .input {
                width: 68%;
                height: 100%;
                float: left;

                .text {
                  margin-top: 8%;
                }
              }

              .time {
                width: 32%;
                height: 100%;
                float: right;

                .select1 {
                  width: 150px;
                  margin-bottom: 0px;
                }

              }

            }
          }

        }

      }
    }

  }
}