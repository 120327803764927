.all {
  width: 100%;
  height: 100vh;
  overflow: hidden;


  .form {
    width: 80%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        border-bottom: 1px solid black;

      }

      .ant-input:focus {
        box-shadow: none;

      }
    }


    .table {
      width: 100%;
      height: 98%;
      overflow: auto;


      .center {
        width: 90%;
        height: 100%;
        margin: 0 auto;

        .list {
          width: 100%;

          .list1 {
            width: 100%;

            .context {
              width: 80%;
              display: flex;

              .item {
                width: 80%;

                Input {
                  width: 100%;
                }
              }


            }


          }
        }

        .time {
          display: flex;
        }

        .time1 {
          display: flex;

          .item {
            width: 80%;
            margin-bottom: 0px;
          }
        }

        .time2 {
          display: flex;

          .a2 {
            margin-right: 10px;
          }

          .item {
            width: 40px;

            Input {
              border: 0px;
            }
          }
        }



        .casenumber {
          width: 60%;
          display: flex;
          flex-direction: row;
          margin-left: 60%;

          .item {
            width: 100%;

            Input {
              border: 0px;
              text-align: center;
              border-bottom: 1px solid black;
            }
          }
        }

        .a1 {
          font-weight: bolder;
        }

        .item1 {
          width: 20%;
        }

        .textarea {
          border: 1px solid black;
        }

        .people1 {
          width: 100%;
          display: flex;

          .textsig {
            width: 70%;
            display: flex;

            Input {
              width: 100%;
            }

            .select1 {
              width: 40%;
              margin-left: 2%;
            }
          }
        }

        .textsig {
          width: 100%;
          display: flex;

          Input {
            width: 50%;
          }

          .select1 {
            width: 20%;
            margin-left: 2%;
          }
        }

        .people {
          display: flex;

          .textsig {
            width: 100%;
            display: flex;

            Input {
              width: 70%;
            }

            .select1 {
              width: 40%;
              margin-left: 2%;
            }
          }
        }

        .textsig {
          width: 100%;
          display: flex;

          Input {
            width: 50%;
          }

          .select1 {
            width: 20%;
            margin-left: 2%;
          }
        }

        .ite {
          display: inline-block;
          width: 12%;
          margin-bottom: 0;

          Input {
            width: 100%;
            float: left;
          }
        }
      }
    }


  }

}