.all {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  .left {
    flex-grow: 1;
    height: 100%;
    border: 1px solid rgba(130, 121, 121, 0.347);
    margin-right: 5px;
    margin-left: 5px;
    overflow: auto;

    .tab {
      width: 100%;

      :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
          padding: 5px;
        }


        .ant-table-thead>tr>th {
          color: rgb(12, 12, 12);
          background-color: rgba(79, 140, 109, 0.626);
        }

      }
    }

  }

  .right {
    border: 1px solid rgba(130, 121, 121, 0.347);
    margin-right: 5px;
    height: 100%;
    width: 60%;


    .form {
      width: 100%;
      height: 100%;

      .top {
        height: 60%;
        width: 100%;

        .item {
          width: 100%;
          margin-top: 5px;

          Input {
            width: 50%;
          }


        }
      }

      .buttom {
        height: 40%;
        width: 100%;
        margin: 0 auto;
        border: 1px solid black;
        overflow: auto;

        .buttom1 {
          width: 50%;
          height: 10%;
          float: left;
          text-align: center;
          border-bottom: 1px solid black;
          border-right: 1px solid black;
        }

        .buttom2 {
          width: 50%;
          height: 10%;
          border-bottom: 1px solid black;
          float: right;
          text-align: center;
        }

        Button {
          width: 100px;
          float: right;
        }

        .add {
          width: 100%;
          height: 20%;
          margin: 0 auto;
          float: left;



          .ite {
            width: 100%;

            .ite1 {
              width: 100%;

              .item1 {
                width: 50%;
                float: left;

                .input {
                  width: 100%;
                  margin-left: 20px;
                }


              }

              .item2 {
                width: 50%;
                float: right;

                .select {
                  width: 100%;
                  margin-left: 20px;
                }
              }


            }
          }




        }


      }


    }


  }
}