.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #dfdfde;

    .btn1 {

        .sigbtn {
            margin-left: 30%;
            margin-bottom: 10px;
            border-radius: 5%;
        }

        .desigbtn {
            margin-bottom: 10px;
            float: right;
            border-radius: 5%;
            margin-right: 10%;
        }
    }



    :global {
        .ant-form-item-label {
            line-height: normal;
            justify-content: flex-end;

            label {
                height: 100%;
                white-space: normal;
                text-align: right;
                padding-right: 3px;
                font-size: 14px;
            }

        }
    }

    .input1 {
        width: 80%;
        border: 1px solid black;
    }

    .button {
        display: block;
        width: 10%;
        margin-right: 5px;
        margin-bottom: 10px;
        margin-left: 90%;
        border: 0px;
        font-size: 25px;
    }

    .cen {
        width: 100%;
        height: 100%;
        margin: auto;
        border-top: 0px;
        border-bottom: 0px;
        background-color: rgba(255, 255, 255, 0.921);


        .btn1 {
            margin-right: 10px;
            margin-left: 5px;
            margin-top: 3px;
        }

        .h3 {
            text-align: center;
        }

        .btn {
            display: block;
            margin: 0 auto;
        }

        h1 {
            text-align: center;
            font-size: 25px;
            font-weight: bolder;
        }

        h2 {
            text-align: right;
            margin-right: 10%;
            font-size: 15px;
            font-weight: bold;
        }

        .lists {
            width: 90%;
            margin: 0 auto;
            border: 1px solid rgb(17, 77, 145);
            margin-bottom: 15px;
        }

        .list {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 10px;

            Input {
                border: 1px solid rgb(87, 126, 128);
            }
        }

        .date {
            width: 80%;
            margin: 0 auto;
            margin-bottom: 10px;

            .dates {
                width: 100%;
                border: 1px solid rgb(87, 126, 128);
            }
        }
    }


}

.loading {
    width: 100%;
    height: 100%;
    display: flex;

    .spin {
        align-items: center;
        margin: auto;
    }
}