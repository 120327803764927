.all {
    width: 100%;
    height: 100%;
    overflow: auto;


    .btn {
        float: right;
        margin-right: 1%;
    }

    .table {
        width: 99%;
        margin: 1% auto;

        :global {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th,
            .ant-table tfoot>tr>td,
            .ant-table tfoot>tr>th {
                padding: 5px;
            }


            .ant-table-thead>tr>th {
                color: rgb(12, 12, 12);
                background-color: rgba(31, 92, 64, 0.626);
            }

        }
    }
}