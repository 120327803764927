.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .center {
        width: 100%;
        height: 70%;
        overflow: auto;
        margin: 5% auto;

        .form {
            width: 60%;
            height: 100%;
            margin: 0 auto;
            overflow: auto;

            h1 {
                margin-left: 40%;
                font-size: 20px;
            }

            .ite {
                width: 100%;

                Input {
                    border: 1px solid rgba(0, 0, 0, 0.282);
                }

                .addFile {
                    background-color: rgba(95, 187, 220, 0.58);
                    border: 1px solid rgba(95, 187, 220, 0.58);
                    margin-left: 60%;
                }
            }

            .ite1 {
                width: 100%;

                Input {
                    border: 0px solid black;
                }
            }

        }
    }

}