.all {
    width: 100%;
    height: 100%;

    .center {
        width: 55%;
        height: 100%;
        overflow: auto;
        margin: auto;
        border: 1px solid black;

        .form {
            width: 99%;
            height: 100%;
            overflow: auto;

            .ite1 {
                margin-bottom: 3px;
                width: 80%;
                margin-left: 10%;

                .input {
                    width: 100%;
                    border: 1px solid rgba(0, 0, 0, 0.582);
                }
            }

            h1 {
                margin-left: 40%;
            }

            .btn {
                width: 90px;
                background-color: rgb(205, 118, 118);
                float: right;
                margin-right: 10%;

                .btn1 {
                    width: 100%;
                }
            }

        }
    }

}