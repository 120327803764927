.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .btn {
        float: right;
        margin-right: 12px;
        margin-top: 10px;
    }

    .center {
        width: 100%;
        height: 80%;
        display: flex;
        overflow: hidden;

        .tab {
            flex-grow: 0.6;
            width: 10%;
            height: 100%;
            margin-top: 10px;
        }

        .table {
            flex-grow: 12;
            margin-right: 1%;
            margin-top: 10px;
            overflow: scroll;
            border: 1px solid rgba(0, 0, 0, 0.276);

            .head {
                text-align: center;
                font-size: 15px;
                font-weight: bolder;
            }

            .form {
                flex-grow: 1;
                margin-right: 10px;

            }


        }
    }

    :global {

        .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
            width: 150px;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            background-color: #188fff21;
        }


    }


}