.all {
  width: 100%;
  height: 90vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 100%;
    height: 100%;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .casenumber {
          width: 40%;
          display: flex;
          flex-direction: row;
          margin-left: 50%;

          .item {
            width: 80%;

            Input {
              text-align: center;
              border: 0px;
              border-bottom: 1px solid black;
            }
          }
        }

        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;

          .row1 {
            width: 100%;
            height: 15%;
            border: 1px solid black;

            .clumn1 {
              width: 10%;
              height: 100%;
              float: left;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 50%;
              }
            }

            .clumn2 {
              width: 90%;
              height: 100%;
              float: right;
              display: flex;
              flex-direction: column;

              .clumn21 {
                width: 100%;
                height: 50%;
                border-bottom: 1px solid black;
                display: flex;

                .a1 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;
                  text-align: center;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .a2 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;

                  Input {
                    margin-top: 4%;
                  }
                }

                .a3 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .a4 {
                  width: 25%;
                  height: 100%;

                  Input {
                    margin-top: 4%;
                  }
                }
              }

              .clumn22 {
                width: 100%;
                height: 50%;
                display: flex;

                .b1 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;
                  text-align: center;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .b2 {
                  width: 75%;
                  height: 100%;
                }
              }
            }
          }

          .row2 {
            width: 100%;
            height: 30%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 50%;
              }
            }

            .c2 {
              width: 30%;
              height: 100%;
              overflow: auto;
              border-right: 1px solid black;

              .ite {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 25%;
                overflow: hidden;

                p {
                  margin-top: 5%;
                }

                .item1 {
                  width: 24%;
                  height: 8%;

                }
              }

            }

            .c3 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 50%;
              }
            }

            .c4 {
              width: 30%;
              height: 100%;

              .ite {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 25%;
                overflow: hidden;

                p {
                  margin-top: 5%;
                }

                .item1 {
                  width: 22%;
                  margin-top: 8px;
                  margin-bottom: 0;


                }
              }

            }
          }

          .row3 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c2 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              .item {
                margin-top: 8px;
                margin-bottom: 0;


              }
            }

            .c3 {
              width: 15%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 5%;
              }
            }

            .c4 {
              width: 35%;
              height: 100%;
              display: flex;
              flex-direction: row;

              .select1 {
                width: 38%;
                margin-left: 20px;
                margin-top: 8px;
              }

              .item {
                margin-top: 8px;
                margin-bottom: 0;

              }
            }
          }

          .row4 {
            width: 100%;
            height: auto;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 25%;
              height: 100%;


              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              border-left: 1px solid black;
              width: 75%;
              height: 100%;
            }
          }

          .row5 {
            width: 100%;
            height: 12%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;

              .input {
                width: 40%;
                height: 100%;
                float: left;

                .text {
                  margin-top: 8%;
                }
              }

              .time {
                width: 60%;
                height: 100%;
                float: right;
                display: flex;

                .select1 {
                  margin-top: 5%;
                  width: 150px;
                }

                .time1 {
                  width: 150px;
                  margin-left: 15px;
                  margin-top: 25%;
                  float: right;
                }
              }
            }
          }

          .row6 {
            width: 100%;
            height: 12%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;

              .input {
                width: 40%;
                height: 100%;
                float: left;

                .text {
                  margin-top: 8%;
                }
              }

              .time {
                width: 60%;
                height: 100%;
                float: right;
                display: flex;

                .select1 {
                  margin-top: 5%;
                  width: 150px;
                }

                .time1 {
                  margin-left: 15px;
                  margin-top: 25%;
                  float: right;
                }
              }

            }
          }

          .row7 {
            width: 100%;
            height: 10%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;
              display: flex;


              .input {
                width: 50%;
                height: 100%;
              }

              .time1 {
                width: 50%;
                height: 100%;


              }
            }
          }
        }

      }
    }

  }
}