.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(119, 114, 114, 0.63);

}

.card {
    width: 60%;
    height: 90%;
    overflow: auto;
    margin: 5% auto;
}

.form {
    width: 85%;
    height: 100%;
    margin: 3% auto;
    border: 2px solid black;

    h1 {
        margin-left: 40%;
    }


    .item {
        width: 80%;
        margin-bottom: 4px;
        margin-left: 7%;
    }

    .lists {
        margin-top: 5%;
    }

    .item1 {

        width: 80%;
        margin-bottom: 4px;
        margin-left: 7%;
    }

    .input1 {
        width: 80%;
        border: 1px solid black;
    }

    :global {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid black;
        }
    }


    Button {
        float: right;
        margin-right: 16%;
    }




}