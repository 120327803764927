.all {
  width: 100%;
  height: 100vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 110%;
    height: 100%;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;



        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;

          .row1 {
            width: 100%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            p {
              text-align: center;
              margin-top: 35%;
            }

            .a1 {
              width: 20%;
              height: 100%;

            }

            .a2 {
              width: 80%;
              border-left: 1px solid black;
              height: 100%;

              .list {
                width: 100%;

                .list1 {
                  width: 100%;

                  .context {
                    width: 80%;
                    display: flex;

                    .item {
                      width: 80%;

                      Input {
                        width: 100%;
                        border-bottom: 1px solid black;
                      }
                    }


                  }


                }
              }

            }
          }

          .row2 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            p {
              text-align: center;
              margin-top: 5%;
            }

            .a1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                margin-top: 35%;
              }
            }

            .a2 {
              width: 80%;
              height: 100%;
              display: flex;
              padding: 5%;
            }

          }

          .row3 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            .c1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 35%;
              }
            }

            .c2 {
              width: 80%;
              height: 100%;
              padding: 5%;
            }
          }



          .row4 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 35%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              padding: 5%;


            }
          }

          .row5 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            display: flex;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 30%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .text {
                width: 70%;
              }

              .select {
                margin-top: 10%;
                width: 30%;
              }
            }
          }

        }

      }
    }

  }
}