.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .center {
        width: 100%;
        height: 100%;
    }

    .table {
        height: 95%;
        width: 100%;
        overflow-y: scroll;
    }
}

.span {
    height: 18px;
    width: 18px;
    display: inline-block;
    border-radius: 50%;

    .text {
        display: block;
        height: 18px;
        line-height: 18px;
        text-align: center;
    }
}

.span1 {
    height: 18px;
    width: 18px;
    display: inline-block;
    border-radius: 50%;
}