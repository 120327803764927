.all {
  width: 100%;
  height: 90vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 80%;
    height: 100%;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .table {
          width: 90%;
          margin: 0 auto;
          height: 100%;

          .row0 {
            width: 100%;
            height: 6%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 10%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 8%;
              }
            }

            .a2 {
              width: 45%;
              border-right: 1px solid black;
              display: flex;

              .item {
                width: 60px;
              }

              p {
                margin-top: 5px;
              }

            }

            .a3 {
              width: 10%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 8%;
              }
            }

            .a4 {
              width: 35%;
            }
          }

          .row1 {
            width: 100%;
            height: 5%;
            border: 1px solid black;

            .clumn1 {
              width: 20%;
              height: 100%;
              float: left;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 3%;
              }
            }

            .clumn2 {
              width: 80%;
              height: 100%;
              float: right;

            }
          }

          .row2 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 3%;
              }
            }

            .c2 {
              width: 25%;
              border-right: 1px solid black;
            }

            .c3 {
              width: 25%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 3%;
              }
            }

            .c4 {
              width: 30%;
            }
          }

          .row3 {
            width: 100%;
            height: 5%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 3%;
              }
            }

            .c2 {
              width: 80%;
            }


          }

          .row4 {
            width: 100%;
            height: 10%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 3%;
              }
            }

            .c2 {
              width: 25%;
              border-right: 1px solid black;
            }

            .c3 {
              width: 25%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 3%;
              }
            }

            .c4 {
              width: 30%;
            }
          }

          .row5 {
            width: 100%;
            height: auto;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .c1 {
              width: 20%;
              height: auto;


              p {
                text-align: center;
                margin-top: 250%;
              }
            }

            .c2 {
              width: 80%;
              height: 100%;
              display: flex;
              border-left: 1px solid black;
              flex-direction: column;

              .d1 {
                width: 100%;
                height: 6%;
                display: flex;
                border-bottom: 1px solid black;

                .c3 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c4 {
                  width: 30%;
                  border-right: 1px solid black;

                }

                .c5 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c6 {
                  width: 30%;

                }
              }

              .d2 {
                width: 100%;
                height: 6%;
                display: flex;
                border-bottom: 1px solid black;

                .c3 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c4 {
                  width: 30%;
                  border-right: 1px solid black;
                }

                .c5 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c6 {
                  width: 30%;
                }
              }

              .d3 {
                width: 100%;
                height: 10%;
                display: flex;
                border-bottom: 1px solid black;

                .c3 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c4 {
                  width: 30%;
                  border-right: 1px solid black;

                }

                .c5 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c6 {
                  width: 30%;
                }
              }

              .d4 {
                width: 100%;
                height: 15%;
                display: flex;
                border-bottom: 1px solid #000;

                .a1 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .a2 {
                  width: 80%;

                  .e2 {
                    height: 50%;
                    display: flex;

                    .item {
                      width: 40px;
                    }

                    p {
                      margin-top: 5px;
                    }
                  }
                }
              }

              .d5 {
                width: 100%;
                height: 6%;
                display: flex;
                border-bottom: 1px solid black;

                .c3 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c4 {
                  width: 80%;
                }


              }

              .d6 {
                width: 100%;
                height: 6%;
                display: flex;

                .c3 {
                  width: 20%;
                  border-right: 1px solid black;
                  text-align: center;
                }

                .c4 {
                  width: 80%;
                }


              }
            }
          }



          .row10 {
            width: 100%;
            height: 18%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d2 {
              width: 30%;
              height: 100%;
              display: flex;
              border-right: 1px solid black;

              .e2 {
                display: flex;
                flex-direction: column;

                .sig {
                  width: 100%;
                  margin-bottom: 5px;
                }

              }

            }

            .d3 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d4 {
              width: 30%;
              height: 100%;
              display: flex;

              .e2 {
                display: flex;
                flex-direction: column;

                .sig {
                  width: 100%;
                  margin-bottom: 5px;
                }

              }

            }
          }

          .row11 {
            width: 100%;
            height: 18%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d2 {
              width: 30%;
              height: 100%;
              display: flex;
              border-right: 1px solid black;

              .e2 {
                display: flex;
                flex-direction: column;

                .sig {
                  width: 100%;
                  margin-bottom: 5px;
                }

              }

            }

            .d3 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d4 {
              width: 30%;
              height: 100%;
              display: flex;

              .e2 {
                display: flex;
                flex-direction: column;

                .sig {
                  width: 100%;
                  margin-bottom: 5px;
                }

              }

            }
          }

        }

      }
    }

  }
}