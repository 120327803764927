.home {
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url(../image/pexels-creative-vix-9754.jpg);
    background-repeat: no-repeat;
    background-size: cover;

    .login_form {
        width: 30%;
        height: 60%;
        background-color: rgba(55, 145, 114, 0.1);
        border: 1px solid white;
        margin: 6% auto;

        p {
            margin-left: 15%;
            margin-top: 50px;
            font-size: 2vw;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        }

        .login_input {
            width: 70%;
            height: 40px;
            margin-left: 10%;
            border-radius: 5px;
            margin-left: 16%;

        }

        .login_input1 {
            width: 70%;
            height: 40px;
            border-radius: 5px;
            margin-left: 16%;
            margin-top: 20px;
        }
    }

    .btn {
        width: 70%;
        height: 40px;
        margin-left: 10%;
        border-radius: 2px;
        margin-left: 16%;
    }

    .btn2 {
        font-size: 1vw;
        margin-left: 69%;
        color: rgba(246, 250, 249, 0.879)
    }


    :global {
        .ant-form-item-explain-error {
            color: rgb(228, 55, 49) !important;
            margin-left: 18%;
        }

    }
}