.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(119, 114, 114, 0.63);

}

.card {
    width: 80%;
    height: 90%;
    margin: 5% auto;
    overflow: auto;
}

.form {
    width: 85%;
    height: 100%;
    margin: 3% auto;
    overflow: auto;
    border: 2px solid black;

    h1 {
        margin-left: 40%;
        font-size: 1.5vw;
    }

    h3 {
        font-size: 1.5vw;
        margin-left: 40%;
        color: black;
    }

    .item1 {
        margin-top: 20px;
        margin-bottom: 4px;
        margin-left: 7%;

        Input {
            height: 30px;
            width: 80%;
            border: 1px solid black;
        }
    }

    .item {
        margin-bottom: 4px;
        margin-left: 7%;

        Input {
            height: 30px;
            width: 80%;
            border: 1px solid black;
        }
    }


    .itemn {
        width: 40%;
        margin-bottom: 4px;
        margin-left: 7%;
        display: inline-flex;

        Input {
            width: 100%;
            height: 30px;
            border: 1px solid black;
        }
    }

    .itemz {
        width: 37%;
        margin-bottom: 4px;
        display: inline-flex;

        Input {
            width: 100%;
            height: 30px;
            border: 1px solid black;
        }
    }

    .items {
        margin-bottom: 4px;
        margin-left: 5%;

    }

    .select {
        width: 80%;
        border: 1px solid black;
    }

    Button {
        float: right;
        margin-right: 16%;
    }

    .itemq {
        margin-bottom: 4px;
        margin-left: 7%;

        .que {
            width: 80%;

        }
    }

    .space {
        width: 80%;
        margin-left: 20%;
        // .it {
        //     width: 100%;
        //     margin-bottom: 4px;
        //     margin-right: 50px;

        //     .nr {
        //         width: 100%;
        //     }
        // }
    }


    .add {
        width: 69.2%;
        margin-right: 15.5%;
    }
}