.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(104, 104, 104, 0.364);

    .center {
        width: 98%;
        height: 100%;
        margin: 1% auto;

        .tree {
            width: 100%;
            height: 100%;
            background-color: #fff;
            padding: 2%;

            Button {
                margin-bottom: 10px;
                margin-top: 5px;
            }
        }
    }
}