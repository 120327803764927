.all {
  width: 100%;
  height: 100vh;
  overflow: hidden;


  .form {
    width: 80%;
    height: 100%;
    overflow-y: auto;
    margin: 0 auto;

    .list {
      width: 100%;

      .list1 {
        width: 100%;

        .item {
          width: 80%;

          Input {
            width: 100%;
          }
        }


        .list2 {
          width: 100%;
          display: flex;

          .item {
            width: 80%;

            Input {
              width: 100%;
            }
          }
        }



      }
    }

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    :global {
      .ant-input {
        border: 0px solid black;
        border-bottom: 1px solid black;

      }

      .ant-input:focus {
        box-shadow: none;

      }
    }

    .center {
      width: 100%;
      height: 100%;

      .text1 {
        width: 100%;
        height: 100%;
        margin-top: 10px;



        .list2 {
          width: 100%;

          .context {
            width: 100%;

            .list3 {
              width: 100%;
              display: flex;

              .item {
                width: 80%;

                .input {
                  border: 1px solid black;
                }
              }

            }
          }
        }

        .ite {
          width: 100%;
          display: flex;

          .item1 {
            width: 40px;

            Input {
              border: 0px;
            }
          }
        }

        .p1 {
          margin-top: 10px;
          font-weight: bold;
        }

        .p2 {
          margin-top: 5px;
          font-weight: 600;
        }

        .people {
          width: 100%;
          display: flex;

          .textsig {
            width: 60%;
            display: flex;
            margin-top: 5px;

            .select1 {
              width: 60%;
            }
          }
        }

        .people1 {
          width: 100%;
          display: flex;

          .textsig {
            flex-grow: 1;
            display: flex;
            margin-top: 5px;

            .select1 {
              width: 100%;


            }
          }
        }

        .textsig {
          width: 100%;
          display: flex;
          height: 35px;

          .select1 {
            width: 60%;
            margin-left: 2%;
          }
        }

        .textsig1 {
          width: 100%;
          display: flex;

          .select1 {
            width: 30%;

            .time {
              width: 100%;
            }
          }
        }
      }


    }

  }

}