.all {
    width: 100%;
    height: 100%;
    overflow: hidden;

    :global {
        .ant-form-item-label {
            line-height: normal;
            justify-content: flex-end;

            label {
                height: 100%;
                white-space: normal;
                text-align: right;
                padding-right: 3px;
                font-size: 14px;
            }

        }
    }

    .btn {
        margin-left: 30%;
        margin-right: 21%;
    }

    .btn2 {
        margin-right: 10px;
    }

    :global {
        .ant-input[disabled] {
            background-color: #fff;
            color: black;
        }
    }

    .center {
        width: 100%;
        height: 100%;
        overflow-y: scroll;

        .rolebtn {
            float: right;
            margin-right: 1%;
            margin-top: 5px;
        }

        .form1 {
            width: 40%;
            margin: 5px auto;
        }

        .list {
            width: 99%;
            margin: 0 auto;
            border: 1px solid rgba(0, 0, 0, 0.466);

            .formChil {
                width: 80%;
                height: 6%;
                margin: 0 auto;
                margin-bottom: 5px;
                display: flex;
                overflow: hidden;
                border: 1px solid rgba(0, 0, 0, 0.466);

                .ite {
                    margin-top: 10px;
                    margin-right: 10px;
                    width: 250px;

                    .input {
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.182);
                    }

                    .select {
                        width: 100%;
                    }
                }

                .ite1 {
                    margin-top: 10px;
                    height: 40px;

                    .input {
                        border: 0px;
                    }

                }


            }

            .form {
                width: 100%;
                height: 6%;
                margin: 0 auto;
                margin-bottom: 5px;
                display: flex;
                overflow: hidden;

                .ite {
                    margin-top: 10px;
                    margin-right: 10px;
                    width: 250px;

                    .input {
                        width: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.182);
                    }

                    .select {
                        width: 100%;
                    }
                }

                .ite1 {
                    margin-top: 10px;
                    height: 40px;

                    .input {
                        border: 0px;
                    }

                }


            }
        }




    }

}