.center {
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    padding: 15px;
    flex-direction: column;

    .rest {
        width: 100%;

        .form {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            .item {
                margin-top: 2%;
                flex-grow: 1;

                .inp {
                    width: 60%;
                    margin-right: 15px;
                }

                .RangePicker {
                    width: 60%;
                    margin-right: 15px;
                }
            }

        }
    }

    .table {
        width: 100%;
    }


    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 5px;
        }

        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: rgb(164, 225, 204);
        }
    }
}