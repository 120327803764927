.all {
  width: 100%;
  height: 100%;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }

  .form {
    width: 110%;
    height: 100%;
    margin: 0 auto;

    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .casenumber {
          width: 45%;
          display: flex;
          flex-direction: row;
          margin-left: 60%;

          .item {
            width: 50%;

            Input {
              border: 0px;
              border-bottom: 1px solid black;
            }
          }
        }

        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;

          .row1 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            p {
              text-align: center;
              margin-top: 5%;
            }

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;
            }

            .a2 {
              width: 75%;
              height: 100%;
            }
          }

          .row2 {
            width: 100%;
            height: 8%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            p {
              text-align: center;
              margin-top: 5%;
            }

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;
            }

            .a2 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;
            }

            .a3 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;
            }

            .a4 {
              width: 25%;
              height: 100%;
              text-align: center;

              .time {
                margin-top: 5px;
              }
            }
          }

          .row3 {
            width: 100%;
            height: 35%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            .c1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 55%;
              }
            }

            .c2 {
              width: 80%;
              height: 100%;

              .d1 {
                width: 100%;
                height: 25%;
                border-bottom: 1px solid black;
                display: flex;

                .e1 {
                  width: 10%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 35%;
                  }
                }

                .e2 {
                  width: 30%;
                  height: 100%;
                  border-right: 1px solid black;

                  .item {
                    margin-top: 10%;
                  }
                }

                .e3 {
                  width: 20%;
                  height: 100%;
                  border-right: 1px solid black;

                  .item {
                    margin-top: 10%;
                  }
                }

                .e4 {
                  width: 20%;
                  height: 100%;

                  .item {
                    margin-top: 10%;
                  }
                }

                .e5 {
                  width: 10%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 30%;
                  }
                }

                .e6 {
                  width: 20%;
                  height: 100%;

                  .item {
                    margin-top: 10%;
                  }
                }
              }

              .d2 {
                width: 100%;
                height: 25%;
                border-bottom: 1px solid black;
                display: flex;

                .e1 {
                  width: 15%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 15%;
                  }
                }

                .e2 {
                  width: 35%;
                  height: 100%;
                  border-right: 1px solid black;

                  .item {
                    margin-top: 5%;
                  }
                }

                .e3 {
                  width: 15%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 15%;
                  }
                }

                .e4 {
                  width: 35%;
                  height: 100%;

                  .item {
                    margin-top: 5%;
                  }
                }
              }

              .d3 {
                width: 100%;
                height: 25%;
                border-bottom: 1px solid black;
                display: flex;

                .e1 {
                  width: 15%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 15%;
                  }
                }

                .e2 {
                  width: 35%;
                  height: 100%;
                  border-right: 1px solid black;

                  .item {
                    margin-top: 5%;
                  }
                }

                .e3 {
                  width: 15%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 15%;
                  }
                }

                .e4 {
                  width: 35%;
                  height: 100%;

                  .item {
                    margin-top: 5%;
                  }
                }
              }

              .d4 {
                width: 100%;
                height: 25%;
                display: flex;

                .e1 {
                  width: 15%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 15%;
                  }
                }

                .e2 {
                  width: 35%;
                  height: 100%;
                  border-right: 1px solid black;

                  .item {
                    margin-top: 5%;
                  }
                }

                .e3 {
                  width: 15%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 15%;
                  }
                }

                .e4 {
                  width: 35%;
                  height: 100%;

                  .item {
                    margin-top: 5%;
                  }
                }
              }
            }
          }

          .row4 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            border-bottom: 0px solid black;
            display: flex;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 30%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
            }
          }

          .row5 {
            width: 100%;
            height: 19%;
            border: 1px solid black;
            display: flex;

            .d1 {
              width: 20%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 30%;
              }
            }

            .d2 {
              width: 80%;
              height: 100%;
              display: flex;

              .text {
                width: 70%;
              }

              .select {
                width: 30%;
              }
            }
          }

        }

      }
    }

  }
}