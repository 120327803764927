.all {
    width: 100%;
    height: 100%;

    .table {
        padding: 10px;

        :global {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th,
            .ant-table tfoot>tr>td,
            .ant-table tfoot>tr>th {
                padding: 7px;
            }

            .ant-table-thead>tr>th {
                background-color: rgba(92, 152, 172, 0.737);
            }

        }
    }
}