.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(119, 114, 114, 0.63);

    :global {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid black;
        }
    }

    .card {
        width: 60%;
        height: 90%;
        overflow: auto;
        margin: 5% auto;

        .form {
            width: 85%;
            height: 100%;
            margin: 3% auto;
            border: 2px solid black;



            h1 {
                margin-left: 40%;
            }


            .item {
                width: 85%;
                margin-bottom: 0;
                margin-left: 7%;
                margin-bottom: 10px;

                .select {
                    width: 80%;
                }

                Input {
                    width: 80%;
                    border: 1px solid black;
                }

            }


            Button {
                float: right;
                margin-right: 16%;
            }

            .add {
                width: 200px;
                margin-right: 30%;
            }
        }
    }
}