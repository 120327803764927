.all {
  width: 100%;
  height: 90vh;
  overflow: auto;

  :global {
    .ant-input {
      border: 0px solid black;

    }

    .ant-input:focus {
      box-shadow: none;

    }
  }



  .form {
    width: 100%;
    height: 120%;



    .h1 {
      font-weight: 10px;
      text-align: center;
      font-size: 25px;
      margin-bottom: 2%;
    }

    .margin {
      width: 100%;
      height: 100%;

      .center {
        width: 100%;
        height: 100%;

        .casenumber {
          width: 30%;
          display: flex;
          flex-direction: row;
          margin-left: 60%;

          .item {
            width: 80%;

            Input {
              border: 0px;
              border-bottom: 1px solid black;
            }
          }
        }

        .table {
          width: 70%;
          margin: 0 auto;
          height: 100%;

          .row3 {
            height: 5%;
            width: 100%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 75%;
              height: 100%;
            }
          }

          .row33 {
            height: 5%;
            width: 100%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 75%;
            }
          }

          .row22 {
            height: 5%;
            width: 100%;
            border: 1px solid black;
            border-bottom: 0px;
            display: flex;

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 75%;
            }
          }

          .row11 {
            height: 5%;
            width: 100%;
            border: 1px solid black;
            display: flex;

            .a1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
              }
            }

            .a2 {
              width: 75%;
            }
          }

          .row88 {
            width: 100%;
            height: 20%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;

            .d1 {
              width: 25%;
              height: 100%;
              overflow: hidden;
              border-right: 1px solid black;

              p {
                margin-top: 25%;
                text-align: center;
              }

            }

            .d2 {
              width: 75%;
              height: 100%;
              display: flex;
              flex-direction: column;

              .e1 {
                width: 100%;
                height: 33.3%;
                border-bottom: 1px solid black;
                display: flex;

                .f1 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 75%;
                  height: 100%;
                }
              }

              .e2 {
                width: 100%;
                height: 33.3%;
                border-bottom: 1px solid black;
                display: flex;

                .f1 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;
                }

                .f3 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f4 {
                  width: 25%;
                  height: 100%;
                }
              }

              .e3 {
                width: 100%;
                height: 33.3%;
                display: flex;

                .f1 {
                  width: 25%;
                  height: 100%;
                  border-right: 1px solid black;

                  p {
                    text-align: center;
                    margin-top: 5%;
                  }
                }

                .f2 {
                  width: 75%;
                  height: 100%;
                }
              }
            }
          }

          .row4 {
            width: 100%;
            height: 15%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 25%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;
            }
          }

          .row5 {
            width: 100%;
            height: 12%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;
              display: flex;

              .input {
                width: 70%;
                height: 100%;
                float: left;

                .text {
                  margin-top: 8%;
                }
              }

              .time {
                width: 60%;
                height: 100%;
                float: right;
                display: flex;

                .select1 {
                  margin-top: 5%;
                  width: 150px;
                }

                .time1 {
                  margin-left: 15px;
                  margin-top: 25%;
                  float: right;
                }

              }

              .time1 {
                width: 30%;
                height: 100%;
                display: flex;
                flex-direction: column;
                text-align: right;
                float: right;

                .select1 {
                  margin-bottom: 0px;
                }


              }




            }
          }

          .row6 {
            width: 100%;
            height: 12%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;
            overflow: hidden;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;

              .input {
                width: 40%;
                height: 100%;
                float: left;

                .text {
                  margin-top: 8%;
                }
              }

              .time {
                width: 60%;
                height: 100%;
                float: right;
                display: flex;

                .select1 {
                  margin-top: 5%;
                  width: 150px;
                }

                .time1 {
                  margin-left: 15px;
                  margin-top: 25%;
                  float: right;
                }
              }

            }
          }

          .row7 {
            width: 100%;
            height: 10%;
            border: 1px solid black;
            border-top: 0px;
            display: flex;
            flex-direction: row;

            .d1 {
              width: 25%;
              height: 100%;
              border-right: 1px solid black;

              p {
                text-align: center;
                margin-top: 10%;
              }
            }

            .d2 {
              width: 75%;
              height: 100%;
              display: flex;

              .input {
                width: 50%;
                height: 100%;
              }

              .time1 {
                width: 50%;
                height: 100%;
                margin-top: 5%;

                .select {
                  width: 53%;
                  float: right;
                  margin-right: 10%;
                }
              }
            }
          }
        }

      }
    }

  }
}