.all {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;


    .wrapper {
        width: 100%;
        height: 100%;
        zoom: 0.9;

        .left {
            height: 100%;
            width: 13%;
            min-width: 100px;
            background-color: #254268;
            float: left;


            .menu {
                background-color: rgba(133, 211, 225, 0);
            }

            .sidebar {
                width: 100%;
                height: 70%;
                overflow: hidden;

                :global {
                    a:global {
                        color: rgb(241, 248, 248);
                    }

                    a:hover {
                        color: rgba(8, 112, 112, 0.668);
                    }

                    .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
                        color: rgb(245, 240, 240);
                        height: 50px;
                        width: 100%;
                        overflow: hidden;
                    }

                    .ant-menu-dark .ant-menu-inline.ant-menu-sub {
                        background-color: rgba(233, 242, 244, 0.296);
                    }

                    .ant-menu-dark .ant-menu-submenu-selected,
                    .ant-menu-dark .ant-menu-submenu-title:hover {
                        color: rgb(20, 141, 162);
                    }

                    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
                        height: 40px;
                        background-color: rgba(44, 106, 137, 0.221);
                    }
                }


            }

            .text {
                width: 100%;
                height: 10%;
                border: 1px solid rgba(0, 0, 0, 0);

                p {
                    font-size: 15px;
                    margin-top: 35px;
                    margin-left: 15px;
                    color: white;
                    font-family: Arial, Helvetica, sans-serif;

                }
            }

            .buttom {
                width: 100%;
                height: 20%;
                display: flex;
                flex-direction: column;

                .btn {
                    width: 50%;
                    height: 30%;
                    margin: 0 auto;
                    margin-bottom: 10%;
                    color: rgb(35, 108, 128);

                }

                p {
                    overflow: hidden;
                    font-size: 0.6vw;
                    color: white;
                    text-align: center;

                }
            }
        }

        .center {
            width: 75%;
            height: 100%;
            float: left;
            overflow: hidden;


            .head {
                width: 100%;
                height: 10%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #254268;

                .demo {
                    flex-grow: 0.1;
                    margin-left: 15px;

                    span {
                        font-size: 15px;
                        color: white;
                    }

                }

                .message {
                    flex-grow: 12;
                    color: white;

                    .icon {
                        float: left;
                        width: 30px;
                        margin-top: 10px;
                        cursor: pointer;
                        margin-left: 30px;
                    }

                    .tim {
                        color: white;
                        margin-top: 10px;
                        display: flex;
                        justify-content: right;

                        .span {
                            margin-left: 5%;
                            margin-right: 15px;
                            cursor: pointer;
                        }

                        .span1 {
                            color: rgb(51, 189, 210);
                            width: 80px;
                            margin-right: 8px;
                        }

                        .chat {
                            color: #f8f3f3;
                        }

                    }
                }
            }

            .context {
                border-right: 2px solid rgba(95, 158, 160, 0.686);
                width: 100%;
                height: 90%;
            }
        }

        .center1 {
            width: 87%;
            height: 100%;
            float: left;
            overflow: hidden;


            .head {
                width: 100%;
                height: 10%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #254268;

                .demo {
                    flex-grow: 0.1;
                    margin-left: 15px;

                    span {
                        font-size: 15px;
                        color: white;
                    }

                }

                .message {
                    flex-grow: 12;
                    color: white;

                    .icon {
                        float: left;
                        width: 30px;
                        margin-top: 10px;
                        cursor: pointer;
                        margin-left: 30px;
                    }

                    .tim {
                        color: white;
                        margin-top: 10px;
                        display: flex;
                        justify-content: right;

                        .span {
                            margin-left: 5%;
                            margin-right: 15px;
                            cursor: pointer;
                        }

                        .span1 {
                            color: rgb(51, 189, 210);
                            width: 80px;
                            margin-right: 8px;
                        }

                        .chat {
                            color: #f8f3f3;
                        }

                    }
                }
            }

            .context {
                border-right: 2px solid rgba(95, 158, 160, 0.686);
                width: 100%;
                height: 90%;
            }
        }

        .speak {
            width: 12%;
            height: 100%;
            float: right;

        }
    }


    :global {
        .ant-popover {
            display: none;
        }
    }

}