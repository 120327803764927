.all {
    width: 100%;
    height: 100%;
    background-color: rgba(99, 97, 97, 0.259);
    overflow: hidden;



    .center {
        width: 98%;
        height: 100%;
        padding: 2%;
        background-color: #fff;
        overflow: auto;

        .tab {
            width: 98%;
            margin: 1% auto;
        }

        :global {

            .ant-table-tbody>tr>td,
            .ant-table-thead>tr>th,
            .ant-table tfoot>tr>td,
            .ant-table tfoot>tr>th {
                padding: 5px;

            }


            .ant-table-thead>tr>th {
                padding: 0px;
            }

        }

        .btn {
            float: right;
            margin-top: 20px;
        }

    }



}