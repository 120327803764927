.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-color: rgba(115, 114, 114, 0.204);

    :global {

        .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
        .ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
            width: 200px;
            height: 40px;
        }

        .ant-tabs-tab.ant-tabs-tab-active {
            background-color: #188fff21;
        }


    }

    .card {
        width: 98%;
        height: 95%;
        margin: 1.2% auto;

        .tab {
            width: 100%;

            .ta {
                Button {
                    margin-right: 2px;
                    height: 30px;
                }
            }
        }

        .form {
            width: 100%;

            Button {
                float: right;
                background-color: rgba(92, 194, 187, 0.378);
            }
        }
    }



    .addbtn {
        float: right;
    }
}