.all {
    width: 100%;
    height: 100%;


    .cen {
        width: 100%;
        height: 95.5%;

        .form1 {
            width: 100%;
            height: 99%;
            overflow-y: auto;
            overflow-x: hidden;
            border-bottom: 1px solid rebeccapurple;
            display: flex;
            flex-wrap: wrap;

            .item4 {
                width: 70%;
                margin-top: 5px;
                margin-left: 10px;
            }

            .item4::after {
                width: 100px;
                margin-top: 5px;
                margin-left: 10px;
            }

            .item1 {
                width: 250px;
                margin-top: 5px;
                margin-left: 10px;
                font-size: 1vw;

                .input {
                    height: 30px;
                    width: 100%;
                    border-radius: 4px;
                    margin-left: 3px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                .select {
                    width: 100%;
                }
            }



            :global {
                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                .ant-select-single .ant-select-selector .ant-select-selection-search-input {
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }
            }

            .hea {
                height: 30px;
                width: 100%;
                margin-bottom: 5px;
                background-color: rgba(137, 178, 203, 0.527);
            }




            .item2 {
                width: 250px;
                margin-top: 5px;
                font-size: 1vw;
                margin-left: 10px;

                .input1 {
                    height: 30px;
                    width: 140%;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                .select {
                    width: 100%;
                }
            }



            .item3 {
                width: 100%;
                height: 12%;

                TextArea {
                    width: 95%;
                    height: 75%;
                    margin-top: 1%;
                    margin-left: 2.5%;
                    border-radius: 5px;
                    border: 1px solid rgb(61, 52, 218);
                }

            }

            .btn {
                margin-left: 26px;
            }



        }

        .form {
            width: 100%;
            height: 75%;
            overflow-y: auto;
            overflow-x: hidden;
            border-bottom: 1px solid rebeccapurple;
            display: flex;
            flex-wrap: wrap;

            .item1 {
                width: 250px;
                margin-top: 5px;
                margin-left: 10px;
                font-size: 1vw;

                .input {
                    height: 30px;
                    width: 100%;
                    border-radius: 4px;
                    margin-left: 3px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                .select {
                    width: 100%;
                }
            }

            .item4 {
                width: 70%;
                margin-top: 5px;
                margin-left: 10px;
            }

            .item4::after {
                width: 100px;
                margin-top: 5px;
                margin-left: 10px;
            }



            :global {
                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                .ant-select-single .ant-select-selector .ant-select-selection-search-input {
                    height: 30px;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }
            }

            .hea {
                width: 100%;
                margin-bottom: 5px;
                background-color: rgba(137, 178, 203, 0.527);
            }




            .item2 {
                width: 250px;
                margin-top: 5px;
                font-size: 1vw;
                margin-left: 10px;

                .input1 {
                    height: 30px;
                    width: 140%;
                    border: 1px solid rgba(0, 0, 255, 0.317);
                }

                .select {
                    width: 100%;
                }
            }



            .item3 {
                width: 100%;
                height: 25%;

                TextArea {
                    width: 95%;
                    height: 75%;
                    margin-top: 1%;
                    margin-left: 2.5%;
                    border-radius: 5px;
                    border: 1px solid rgb(61, 52, 218);
                }

            }

            .btn {
                margin-left: 26px;
            }



        }
    }

    .btnt {
        width: 100%;
        height: 4.5%;
        background-color: rgba(221, 236, 230, 0.119);

        .span {
            width: 40%;
            font-weight: bolder;
            margin-left: 40%;
        }

        .hbtn {
            width: 120px;
            height: 30px;
            margin-top: 3px;
            margin-left: 3px;
            background-color: rgba(109, 177, 181, 0.371);
            border: 1px solid rgba(109, 177, 181, 0.371);

        }



        .hbtn1 {
            float: right;
            margin-top: 3px;
            margin-right: 15px;
            margin-bottom: 3px;
        }
    }


    :global {

        .ant-table-tbody>tr>td,
        .ant-table-thead>tr>th,
        .ant-table tfoot>tr>td,
        .ant-table tfoot>tr>th {
            padding: 3px;
        }


        .ant-table-thead>tr>th {
            color: rgb(12, 12, 12);
            background-color: rgb(198, 205, 196);
        }

    }

    .list {
        width: 100%;
        height: 30%;

        .listtable {
            width: 100%;
            height: 100%;

            .TabPane {
                width: 100%;
                height: 100%;
                overflow: scroll;

                Button {
                    width: 80px;
                    border: 1px solid rgb(68, 145, 157);
                    background-color: rgba(68, 145, 157, 0.144);
                    margin-bottom: 2px;
                }




            }
        }

    }

    .form::-webkit-scrollbar {
        width: 4px;
    }

    .form::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(30, 166, 39, 0.2);
    }

    TextArea::-webkit-scrollbar {
        width: 8px;
    }

    TextArea::-webkit-scrollbar-thumb {
        background: rgba(30, 166, 39, 0.2);
    }
}