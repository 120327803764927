.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(119, 114, 114, 0.63);
}

.card {
    width: 60%;
    height: 97%;
    margin: 1% auto;
    overflow: auto;
}

.form {
    width: 85%;
    height: 100%;
    margin: 3% auto;
    overflow: auto;
    border: 1px solid black;

    h1 {
        margin-left: 40%;
    }

    .item1 {
        margin-top: 20px;
        margin-bottom: 4px;
        margin-left: 7%;
    }

    .item {
        margin-bottom: 4px;
        margin-left: 7%;
    }

    .item2 {
        margin-bottom: 4px;
        margin-left: 4.8%;
    }

    Input {
        width: 80%;
        border: 1px solid black;
    }

    Button {
        margin-top: 10px;
        float: right;
        margin-left: 50px;
    }
}