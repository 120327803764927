.all {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(183, 183, 183, 0.811);

}

.card {
    width: 90%;
    height: 95%;
    margin: 1.5% auto;
}

.form {
    width: 55%;
    height: 100%;
    margin: 3% auto;
    overflow: auto;

    h1 {
        margin-left: 40%;
    }

    .item1 {
        margin-top: 20px;
        margin-bottom: 4px;
        margin-left: 7%;

        Input {
            width: 80%;
            border: 1px solid black;
        }

        .select {
            width: 80%;
        }
    }




    Button {
        float: right;
        margin-right: 16.5%;
    }
}